import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalCompanyDominio from '../modals/modal-company-dominio';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'

export default function CompanyDominio() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    const getCompany = async() => {
        setGridParams({codi_emp        : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                        value : '000000'},
                                           primaryKey  : true},          
                       nome_emp         : {text        : "Razão Social" },
                       apel_emp         : {text        : "Apelido",
                                           searchDefault : true},
                       cgce_emp         : {text        : "CNPJ"},
                       enquadramento    : {text        : "Enquadramento"},
                       esta_emp         : {text        : "UF"},
                       i_cnae20         : {text        : "Código CNAE"},
                       descricao        : {text        : "Descrição CNAE"},
                       validade_fim     : {text        : "Validade Certificado",
                                           dataType    : 'date' },

                       diasvencimentocertificado : {text : 'Dias para Vencimento',
                        dataType : 'number' },
                       Options          : {text        : "Opções",
                        className   : "text-center",
                        order       : false,
                        filter      : false,
                        item        : [{type  : "Custom",
                                        props : {tag            : "button",
                                                 title          : "Editar empresa",
                                                 className      : 'btn btn-sm btn-warning',
                                                 icon           : "fa fa-edit",
                                                 indexcallback  : 0}},
                                       {type  : "Custom",
                                        props : {tag            : "button",
                                                 title          : "Impressão Individual",
                                                 className      : 'btn btn-sm btn-dark padding0',
                                                 icon           : "fa fa-newspaper",
                                                 indexcallback  : 1}}]}
                                             
                 })
    }

    const EditCompany = async(id) => {
        await api.get('/company-dominio/' + id)
                 .then(response => {
                    console.log(response.data)
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const CardCompany = async(id) => {
        await api.get('/company-card-dominio/' + id)
                 .then(response => { 
                        const downloadLink = document.createElement("a");
                        const fileName = "Inscrição Cadastral.pdf";
                        downloadLink.href = "data:application/pdf;base64," + response.data;
                        downloadLink.download = fileName;
                        downloadLink.click();

                        setLoading(false)
                  })
                  .catch( error => { 
                      setLoading(false)
                      ShowMessages(error)
                  })
        
    }

    useEffect(() => {
        getCompany()
        setStatistic('maintenance\\company-dominio')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalCompanyDominio showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,6)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/company-dominio' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Empresa Dominio"
                      callbackCellClick   = {EditCompany}
                      callbackButtons     = {[EditCompany,
                                              CardCompany
                      ]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}