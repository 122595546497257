import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalMyNumber from '../modals/modal-mynumber';
import ModalMyNumberConnect from '../modals/modal-mynumber-connect';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import Joyride from 'react-joyride';

export default function MyNumbers() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalTest, setShowModalTest]                = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
//     const [steps, setSteps]                                = useState([
//         {
//           target: '.content-wrapper',
//           content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//           disableBeacon: true,
//           placement :'top-end'
//         },
//         {
//             target: '#btn-new',
//             content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//             disableBeacon: true
//           },
//           {
//             target: '#btn-refresh',
//             content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//             disableBeacon: true
//           },
//           {
//             target: '#btn-print',
//             content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//             disableBeacon: true
//           },
//           {
//             target: '#btn-export',
//             content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//             disableBeacon: true
//           },
//           {
//             target: '#btn-search',
//             content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
//             disableBeacon: true
//           },
//     ])

    const getMyNumbers = async() => {
        await api.get('/mynumberstatus')
                 .then(response => {
                    setGridParams({idnumber     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                value : '00000'},
                                                   primaryKey  : true                          },
                                   description  : {text        : "Descrição"},
                                   number       : {text        : "Número",
                                                   searchDefault : true},
                                   receivecount : {text        : "Total Recebido",
                                                   className   : 'text-right',
                                                   item        : {className : 'text-right'}},
                                   sendcount    : {text        : "Total Enviado",
                                                   className   : 'text-right',
                                                   item        : {className : 'text-right'}}, 
                                   numberstatus : {text        : "Status",
                                                   className   : "text-center",
                                                   item        : {className : "text-center",
                                                                  switch    : {data  : response.data,
                                                                               key   : 'idnumberstatus'}}},
                                   Options          : {text        : "Opções",
                                                       className   : "text-center",
                                                       order       : false,
                                                       filter      : false,
                                                       item        : [{type  : "Custom",
                                                                       props : {tag               : "button",
                                                                                title             : "Conectar",
                                                                                className         : 'btn btn-sm btn-success',
                                                                                icon              : "fa fa-globe",
                                                                                visualConditional : {idnumberstatus : {operator : '==',
                                                                                                     values : [0]}},
                                                                                indexcallback     : 0}},
                                                                      {type  : "Custom",
                                                                       props : {tag               : "button",
                                                                                title             : "Testar Conexão",
                                                                                className         : 'btn btn-sm btn-success',
                                                                                icon              : "fa fa-signal",
                                                                                visualConditional : {idnumberstatus : {operator : '==',
                                                                                                     values : [1]}},
                                                                                indexcallback     : 3}},
                                                                      {type  : "Custom",
                                                                       props : {tag               : "button",
                                                                                title             : "Desconectar",
                                                                                className         : 'btn btn-sm btn-danger',
                                                                                icon              : "fa fa-circle-exclamation",
                                                                                visualConditional : {idnumberstatus : {operator : '==',
                                                                                                     values : [1]}},
                                                                                indexcallback     : 1}},
                                                                      {type  : "Custom",
                                                                       props : {tag               : "button",
                                                                                title             : "Excluir número",
                                                                                className         : 'btn btn-sm btn-danger',
                                                                                icon              : "fa fa-trash",
                                                                                message           : "Deseja realmente excluir o número?",
                                                                                visualConditional : {idnumberstatus : {operator : '==',
                                                                                                     values : [0]}},
                                                                                indexcallback     : 2}}]}
                      })
                  })
                  .catch(error => {
                      console.log(error)
                      ShowMessages(error)
                  })                           
        
    }

    useEffect(() => {
        getMyNumbers()
        setStatistic('maintenance\\my-numbers')
    },[])
    
    const handleCloseModal        = () => {setShowModal(false)
                                           setReload(true)}
    const handleCloseModalTest    = () => {setShowModalTest(false)}    
    const handleFilter            = () => {setIsOpenFilter(!isOpenFilter)}

    const TestConnection = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(response => {
                    setData(response.data)
                    setShowModalTest(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                })
    }

    const DeleteWhatsapp = async(_id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir do número?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/myNumbers/' + _id)
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }
    
    const ConnectWhatsapp = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(response => {
                    setData(response.data)
                    setShowModal(true)
                })
                .catch( error => {
                    ShowMessages(error)
                })
    }

    const statusNumberConnect = async() => {
        await api.get('/getAllMyNumbers')
                 .then(async(response) => { 
                    for (const [idx, _row] of response.data.entries()) {
                        await api.post('/api/v1/getStatusNumber',{number : _row.number.replace(/[\W]+/g,""),
                                                                  hash   : _row.environment })
                                 .then(async(_status) => {  
                                    setReload(true)
                                }).catch(error => {
                                        ShowMessages(error)
                                })
                }

        }).catch(error => {
           ShowMessages(error)
        })  
    }

    const DisconnectWhatsapp =  (_id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desconectar do WhatsApp",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.get('/mynumbers/' + _id)
                         .then(async response => {
                            await api.post('/api/v1/logout',{number : response.data.number.replace(/[\W]+/g,""), hash : response.data.environment})
                                     .then((response) => {
                                        if (!response.data.data.auth) 
                                            ShowMessages({message : response.data.data.message,status : 200})
                                        setReload(true)
                                     })
                                     .catch((error) => {
                                        ShowMessages(error)
                                     })
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }    
    
    return (    
        <ContentWrapper>
            {/* <Joyride 
                    steps={steps} 
                    run={false} 
                    continuous ={true} 
                    disableCloseOnEsc={true}  
                    showProgress={true}  
      
                    locale={{back: 'Voltar', close: 'Fechar', last: 'Último', next: 'Próximo', open: 'Abrir', skip: 'Pular' }}
                    styles={{  overlay : { zIndex: 2000 },
                               options : { zIndex: 3000 },
                               buttonNext: {
                                backgroundColor: '#104e5a',
                               },
                               buttonBack: {
                                backgroundColor: '#104e5a',
                                borderRadius:"3px",
                                color:"#fff"
                               }}}/> */}
            <Loading loading={loading}/>
            <ModalMyNumberConnect showModal={showModal} handleCloseModal = {handleCloseModal} handleReload={setReload} data={data}/>
            <ModalMyNumber showModal={showModalTest} data={data} handleCloseModal = {handleCloseModalTest} handleReload={setReload}/>  

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" id="btn-new" title="Insere um novo registro" onClick={e => setShowModal(true)}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" id="btn-refresh" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            
                            {showButtons ? 
                                <>
                                    <button className="btn btn-warning" id="btn-check" title="Checar as Conexões" style={{marginLeft:"3px"}} onClick={e => statusNumberConnect()}><i className="fa fa-tower-cell"></i></button>
                                    <button className="btn btn-dark" id="btn-print" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  id="btn-export" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" id="btn-search" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/> 
                <Grid url                 = '/mynumbers' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Números"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {ConnectWhatsapp}
                      callbackButtons     = {[ConnectWhatsapp, 
                                              DisconnectWhatsapp,
                                              DeleteWhatsapp,
                                              TestConnection]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}