import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMessages({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]  = useState(false)

    const [message,setMessage]   = useState('')

    const setData = (obj,clear) => {
        setMessage(clear ? '' : obj.message) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-message'),document.querySelector('#submit-message').children[0].classList,document.querySelector('#submit-message').lastChild.nodeValue)
        try {
             let _data = {message}

            if (editState)
                await api.put('/messages/' + data.idmessage, _data)
                         .then(response => {
                            ShowMessages(response) 
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-message'))  
                            } else 
                                loadingButton(false,document.querySelector('#submit-message'))  
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-message'))   
                            ShowMessages(response);
                        })
            else
                await api.post('/messages', _data)
                         .then(response => {
                            ShowMessages(response) 
                             if (response.ok) {   
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-message'))          
                             } else 
                                loadingButton(false,document.querySelector('#submit-message'))  
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-message'))   
                            ShowMessages(response);
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-message')) 
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-messages')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}>  
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-comment"></i> Propriedades de Mensagens
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Mensagem</label>
                            <div className="input-group">
                                <TextInput  
                                    name="message"
                                    id="message"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={250}
                                    errorMessage={{ required: "Por favor, informe uma mensagem!" }}
                                    value={message}
                                    className="form-control "
                                    onChange={e => setMessage(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div> 
                        <div style={{marginTop: "15px", display: "flex", flexDirection: 'column', padding:"8px", backgroundColor:"#ddffdd", borderRadius:"5px", border:"1px dashed #cacaca"}}>
                            <label style={{fontWeight:"bold", fontSize:"16px"}}>Lista de Parâmetros</label>
                            <div style={{display:"flex"}}>
                                <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                    <span >[CASAMENTOS_HOJE]</span>
                                </div>
                                <div style={{paddingLeft:"10px"}}>
                                    <span>Apresenta o total de casamentos realizado no dia</span>
                                </div>
                            </div>
                        </div>                                     
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit"  id="submit-message" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}