import React from 'react';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormRegister } from '../formregister-context';

export default function AccountManagement() {
  const { accountManagement, setAccountManagement } = useFormRegister();
  // console.log(accountManagement, 'setAccountManagement');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const valueToUse =
      value === 'true' ? true : value === 'false' ? false : value;
    setAccountManagement((prev) => ({ ...prev, [name]: valueToUse }));
  };
  return (
    <div
      style={{
        maxHeight: '330px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '5px',
      }}
    >
      <ValidationForm>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label className="mt-1">Certificado Digital:</Form.Label>
              <Form.Control
                type="text"
                name="digitalcertificatemanagement"
                value={accountManagement.digitalcertificatemanagement}
                onChange={handleChange}
                className="mb-3 mt-3"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Distrato com antiga contabilidade foi realizado? Se sim, nomes e
                telefones:
              </Form.Label>
              <Form.Control
                type="text"
                name="terminationwithpreviousaccounting"
                value={accountManagement.terminationwithpreviousaccounting}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Há CEI?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="ceipresence"
                  value={true}
                  checked={accountManagement.ceipresence === true}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Form.Check
                  type="radio"
                  label="Não"
                  name="ceipresence"
                  value={false}
                  checked={accountManagement.ceipresence === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label> Há Domésticas?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="domesticemployees"
                  value={true}
                  checked={accountManagement.domesticemployees === true}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Form.Check
                  type="radio"
                  label="Não"
                  name="domesticemployees"
                  value={false}
                  checked={accountManagement.domesticemployees === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Corpo de Bombeiros:</Form.Label>
              <Form.Control
                type="text"
                name="firedepartmentcompliance"
                value={accountManagement.firedepartmentcompliance}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Alvarás:</Form.Label>
              <Form.Control
                type="text"
                name="permits"
                value={accountManagement.permits}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>OBSERVAÇÃO</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="observationmanagement"
                value={accountManagement.observationmanagement}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </ValidationForm>
    </div>
  );
}
