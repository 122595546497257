import React from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContext from 'rc-tabs/lib/TabContext';

import getComponent from './../../routesInternal';

let index = 0;

export default class KSContainer extends React.Component {
    state = {
        tabs      : [],
        activeKey : '',
    };

    resetPages = () => {
        this.props.callbackPages(0)
        this.props.callbackReset(false)
        this.setState({
            tabs: [],
            activeKey : '',
        });
    }

    onTabChange = (activeKey) => {
        this.setState({
            activeKey,
        });
    }

    ToogleClass = () => {
        if (this.state.tabs.length > 0)
            document.querySelector('.rc-tabs-bar') && document.querySelector('.rc-tabs-bar').classList.add('add-tab-border')
        else 
            document.querySelector('.rc-tabs-bar') && document.querySelector('.rc-tabs-bar').classList.remove('add-tab-border') 
    }

    construct() {   
        if (this.props.reset)
            this.resetPages()

        const _height = "100%"
        return this.state.tabs.map((t) => {
            return (<TabPane 
                        tab={<span><em className={`text-theme ${t.icon}`}></em> {t.title}
                                 <a
                                     href="/#"
                                     className="icon-close"
                                     title="Fechar Aba"
                                     onClick={(e) => {
                                         this.remove(t.id, e)
                                     }}>
                                     <em className="fa fa-times"></em> 
                                 </a>
                             </span>}
                        key={t.id}>
                        <div style={{height:_height}}>
                            {t.content}
                        </div>
                    </TabPane>)
        });
    }

    remove = (id, e) => {
        if (e)
            e.stopPropagation();

        let foundIndex = 0;
        const after = this.state.tabs.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            foundIndex = i;
            return false;
        });

        let activeKey = this.state.activeKey;

        if (activeKey === id) {
   
            if (foundIndex) 
                foundIndex--;
                
   
            if ((foundIndex >= 0) && (after.length > 0)) 
                activeKey = after[foundIndex].id;                
  
        }
        this.setState({
            tabs: after,
            activeKey,
        });
        this.props.callbackPages(this.state.tabs.length - 1)
        this.ToogleClass()
    }

    add = (e,title,icon,path,props,idmenu) => {
        if (e)
            e.stopPropagation();
        index++;
        const newTab = {
            title   : title,
            content : getComponent(path,index, props,idmenu),
            icon    : icon || "fa fa-question",
            id      :`tab_${index}`,
            props   : props 
        };
        
        this.setState({
            tabs      : this.state.tabs.concat(newTab),
            activeKey : `tab_${index}`,
        });
        this.ToogleClass()
        this.props.callbackPages(this.state.tabs.length + 1)
    }

    render() {
        window.addTab    = this.add;
        window.removeTab = this.remove;
        return (
                    <Tabs 
                        animated={true}
                        renderTabContent={() => <TabContext />}
                        activeKey={this.state.activeKey}
                        onChange={this.onTabChange}
                    >
                    {this.construct()}
                    {this.ToogleClass()}
                    </Tabs>
        );
    }
}