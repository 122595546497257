import React from 'react';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormRegister } from '../formregister-context';

export default function AccountingDepartament() {
  const { accountingDepartament, setAccountingDepartament } = useFormRegister();
  // console.log(accountingDepartament, 'accountingDepartament');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const valueToUse =
      value === 'true' ? true : value === 'false' ? false : value;
    setAccountingDepartament((prev) => ({ ...prev, [name]: valueToUse }));
  };

  return (
    <div
      style={{
        maxHeight: '330px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '5px',
      }}
    >
      <ValidationForm>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Quais empresas serão feitas a Contabilidade?
              </Form.Label>
              <Form.Control
                type="text"
                name="accountingcompanies"
                value={accountingDepartament.accountingcompanies}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Qual a tributação atual delas?</Form.Label>
              <Form.Control
                type="text"
                name="currenttaxation"
                value={accountingDepartament.currenttaxation}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Possuem saldos de Balanço realizado pela antiga Contabilidade?
              </Form.Label>
              <Form.Control
                type="text"
                name="previousbalance"
                value={accountingDepartament.previousbalance}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>
                Data de início da escrituração Contábil na SGECONT?
              </Form.Label>
              <Form.Control
                type="date"
                name="accountingstartdate"
                value={accountingDepartament.accountingstartdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                A empresa conhece o processo DIGITAL SGECONT ou utilizava o
                financeiro integrado com a Contabilidade?
              </Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="digitalprocessknowledge"
                  value={true}
                  checked={
                    accountingDepartament.digitalprocessknowledge === true
                  }
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="digitalprocessknowledge"
                  value={false}
                  checked={
                    accountingDepartament.digitalprocessknowledge === false
                  }
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Quem é o responsável pelo Financeiro da empresa responsável pelo
                envio das informações para o Contábil?
              </Form.Label>
              <Form.Control
                type="text"
                name="financialresponsible"
                value={accountingDepartament.financialresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Possui sistema/controle financeiro (contas a pagar e a receber)?
              </Form.Label>
              <Form.Control
                type="text"
                name="financialsystem"
                value={accountingDepartament.financialsystem}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Possui quantas contas bancárias? Tem relatório de fluxo de
                caixa? Possuem outros relatórios financeiros?
              </Form.Label>
              <Form.Control
                type="text"
                name="bankaccounts"
                value={accountingDepartament.bankaccounts}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                A empresa trabalha com adiantamentos a fornecedores/
                adiantamentos de clientes?
              </Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="advancepayments"
                  value={true}
                  checked={accountingDepartament.advancepayments === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="advancepayments"
                  value={false}
                  checked={accountingDepartament.advancepayments === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Existe controle do Estoque, Imobilizado?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="inventorycontrol"
                  value={true}
                  checked={accountingDepartament.inventorycontrol === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="inventorycontrol"
                  value={false}
                  checked={accountingDepartament.inventorycontrol === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                A empresa faz pagamentos por fora? (Funcionários, despesas,
                fornecedores)
              </Form.Label>
              <Form.Control
                type="text"
                name="offbookpayments"
                value={accountingDepartament.offbookpayments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Faz pagamentos de contas pessoais dos sócios pela empresa?
                Possui controle?
              </Form.Label>
              <Form.Control
                type="text"
                name="personalpaymentscontrol"
                value={accountingDepartament.personalpaymentscontrol}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                (Fornecer modelo de recibo de antecipação de lucro via e-mail)
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>A empresa participa de licitação?</Form.Label>
              <Form.Control
                type="text"
                name="biddingparticipation"
                value={accountingDepartament.biddingparticipation}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                A empresa tem controle do Resultado financeiro mensal?
              </Form.Label>
              <Form.Control
                type="text"
                name="financialresultcontrol"
                value={accountingDepartament.financialresultcontrol}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">(lucro/prejuízo)</Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>Particularidades</Form.Label>
              <Form.Control
                as="textarea"
                name="particularitiesaccouting"
                value={accountingDepartament.particularitiesaccouting}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>OBSERVAÇÃO</Form.Label>
              <Form.Control
                as="textarea"
                name="observationdepartament"
                value={accountingDepartament.observationdepartament}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                1. Por um período mínimo de 90 dias, estaremos atendendo a
                empresa com foco na apresentação de resultados Contábeis.
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                2. Verificando a probabilidade de realizar a escrituração
                Contábil em formato Digital.
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                3. Realizar a implantação dos saldos iniciais do último Balanço
                Patrimonial da empresa.
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                4. Implementar linhas de comunicação entre a Integração no
                Departamento Contábil e o financeiro da empresa.
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
      </ValidationForm>
    </div>
  );
}
