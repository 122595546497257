import React, {useState, useEffect} from 'react'

import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import setStatistic from './../../services/apiStatistic'
import {Button} from 'reactstrap';
import Draggable from 'react-draggable';

import { ValidationForm,TextInput } from 'react-bootstrap4-form-validation';
import api from '../../services/api'
import  loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function UserGroup({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]     = useState(false)
    
    const [usergroup, setUserGroup] = useState('')

    const setData = (obj,clear) => {
        setUserGroup(clear ? '' : obj.usergroup) 
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-user-group'),document.querySelector('#submit-user-group').children[0].classList,document.querySelector('#submit-user-group').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {usergroup}

            if (editState)
                await api.put('/usergroup/' + data.idusergroup, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-user-group'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-user-group'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-user-group'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/usergroup', _data)
                         .then(response => {
                             ShowMessages(response)  
                             if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-user-group'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-user-group'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-user-group'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-user-group'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   

    useEffect(() => {
        setStatistic('modals\\modal-user-group')
    } ,[])

    return (<Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>  
                    <Modal.Header closeButton>
                        <Modal.Title style={{marginBottom:"0px"}}>
                            <i className="fa fa-user-check"></i> Propriedades do Grupo de Usuários
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: "Flex" }}>     
                            <div className="input-group mb-2">
                                <label>Grupo</label>
                                <TextInput  
                                    name="group"
                                    id="group"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um nome para o grupo!" }}
                                    value={usergroup}
                                    className="form-control "
                                    onChange={e => setUserGroup(e.target.value)}
                                    autoComplete="off"/>
                            </div>                                           
                        </div>    
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button type="submit" id="submit-user-group" className={`btn-theme ${loading ? "hidden" : ""}`}>
                            <em className="fa fa-save"></em> Salvar
                        </Button>          
                        <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </Modal.Footer>
                </ValidationForm>  
            </Modal>)
}