import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import setStatistic from '../../services/apiStatistic'
import api from '../../services/api'
import { getMonth,getYear } from 'date-fns';
import ShowMessages from '../../components/Exceptions';
import Select from 'react-select';
import { off } from 'rsuite/esm/DOMHelper';

export default function NFsMissing() {
    const [loading, setLoading]                            = useState(false)

    const [company, setCompany]                            = useState({})
    const [startDate, setStartDate]                        = useState( '')
    const [endDate, setEndDate]                            = useState('')
    const [model, setModel]                                = useState('')
    const [type, setType]                                  = useState(0)
    const [companyData, setCompanyData]                    = useState([])
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([])

    const getNfsMissing = async() => {

        await api.get('/sge-companies/')
                 .then(response => {
                    setCompanyData(response.data || [])
                    setGridParams({mesano     : {text       : "Mês/Ano",
                                                 primaryKey  : true,
                                                 searchDefault : true},
                                   de         : {text        : "De"},
                                   ate        : {text        : "Até"}

                    })
                 })
                 .catch(error => {

                 })
    }

    const handleFilter = () => {

        if ((company?.value || 0) === 0) {
            ShowMessages({status : 500, message : "Informe a empresa"})
            return
        }

        if (startDate === '') {
            ShowMessages({status : 500, message : "Informe a data inicial"})
            return
        }

        if (endDate === '') {
            ShowMessages({status : 500, message : "Informe a data final"})
            return
        }
        setFilters([{
                        field      : 'company',
                        key        : '=',  
                        value      : company.value,
                    },
                    {
                        field      : 'type',
                        key        : '=',  
                        value      : type,
                    },
                    {
                        field      : 'create_at',
                        key        : 'between',  
                        value      : startDate,
                        value1     : endDate
                    },
                    {
                        field      : 'model',
                        key        : '=',  
                        value      : model
                    }])
    }

    useEffect(() => {
        getNfsMissing()
        setStatistic('maintenance\\nfs-missing')
    },[])
    
    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div style={{display:"flex", gap:"5px"}}>
                        <div style={{width:"350px"}}>
                            <span>Empresa</span>
                            <Select value={company}  onChange={setCompany}  options={companyData} placeholder="Pesquisar" noOptionsMessage={() => "Empresa não encontrada"}/>
                        </div>
                        <div>
                            <span>Tipo de NF-e</span>
                            <select className='form-select' value={type} onChange={e => setType(e.target.value)} style={{height:"38px"}}>
                                <option value="0">NF-e de Serviço</option>
                                <option value="1">NF-e de Produto</option>
                            </select>
                        </div>
                        <div>
                            <span>Modelo NF-e</span>
                            <select className='form-select' value={model} onChange={e => setModel(e.target.value)} style={{height:"38px"}}>
                                <option value="">Todos</option>
                                <option value="36">DANFE</option>
                                <option value="41">NFC-e</option>
                            </select>
                        </div>
                        <div>
                            <span>Data Inicial</span>
                            <input type="date" className='form-control' style={{height:"38px"}} value={startDate} onChange={e => setStartDate(e.target.value)}/>
                        </div>
                        <div>
                            <span>Data Final</span>
                            <input type="date" className='form-control' style={{height:"38px"}}  value={endDate} onChange={e => setEndDate(e.target.value)}/>
                        </div>
                        <div  style={{display:"flex", alignItems:"flex-end"}}>
                            <button className='btn btn-success' type='button' onClick={e => handleFilter()}>
                                <i className='fa fa-filter'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
            <Filter isOpenFilter={false} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/nfs-missing' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "NFs Faltantes"
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackButtons     = {[]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}