import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalLinkBI({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]     = useState(false)

    const [dataMenu, setdataMenu]   = useState([])
    const [idmenu,setIdMenu]        = useState(-1)
    const [link, setLink]           = useState('')

    const setData = (obj,clear) => {
        setIdMenu(clear ? '' : obj.idmenu)
        setLink(clear ? '' : obj.link) 
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/link-bi-menus')
                 .then(response => {
                          if (response.ok) {
                              setdataMenu(response.data)  
                              setLoading(false)
                          }
                  })
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-link-bi'),document.querySelector('#submit-link-bi').children[0].classList,document.querySelector('#submit-link-bi').lastChild.nodeValue)
        try {
             let _data = {idmenu,
                          link}

            if (editState)
                await api.put('/link-bi/' + data.idlinkbi, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-link-bi')) 
                            } else 
                                loadingButton(false,document.querySelector('#submit-link-bi'))
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-link-bi'))   
                            ShowMessages(response);
                         })
            else
                await api.post('/link-bi', _data)
                         .then(response => {
                             ShowMessages(response)
                             if (response.ok) {    
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-link-bi'))         
                             } else
                                loadingButton(false,document.querySelector('#submit-link-bi')) 
                        }).catch(response => {
                            loadingButton(false,document.querySelector('#submit-link-bi'))   
                            ShowMessages(response);
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-link-bi')) 
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
    } ,[showModal])

    useEffect(() => {
        setStatistic('modals\\modal-link-bi')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Propriedades de Links BI
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth10vh"/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Menu</label>
                            <div className="input-group">
                                <SelectGroup 
                                    id="menu"
                                    name="menu" 
                                    required 
                                    errorMessage={{ required: "Por favor, informe um menu!" }}
                                    placeholder="Selecione" 
                                    className="form-select"
                                    value={idmenu} 
                                    onChange={e => setIdMenu(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {(dataMenu !== null) ?     
                                        dataMenu.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        }) : ""}  
                                </SelectGroup> 
                           
                            </div>
                        </div>     
                        <div>
                            <label>Link BI</label>
                            <div className="input-group">
                                <TextInput  
                                    name="occupation"
                                    id="occupation"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={1000}
                                    errorMessage={{ required: "Por favor, informe um link!" }}
                                    value={link}
                                    className="form-control "
                                    onChange={e => setLink(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>                                
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-link-bi" className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}