import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import logo from './../../assets/logo.png'
import logoSGE from './../../assets/logoSGE.png'
import api from '../../services/api'
import { TOKEN_KEY, IDUSER, USER_DATA, IDCOMPANY, isAuthenticated, USERNAME, REGISTRATION, ReadDataStorage, WriteDataStorage, CONTRACT, EXPIRES_IN, CONTRACTSTATUS} from '../../services/auth'
import ShowMessages from '../../components/Exceptions'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { GetCookieMessage, SetCookieMessage } from './../../components/Cookies';

import  loadingButton  from './../layouts/Buttons'

import setStatistic from './../../services/apiStatistic'

export default function Login(props) {
    const [update, setUpdate]                   = useState(false)
    const [registration, setRegistration]       = useState('')
    const [password, setPassword]               = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [remember, setRemember]               = useState(false)

    const matchPassword = (value) => {
        return value && value === password;   
    }


    useEffect(() => {

        if (GetCookieMessage('LoginMsg',"") !== "") {
            NotificationManager.error(GetCookieMessage('LoginMsg',""), 'Erro!', 4000);
            SetCookieMessage('LoginMsg',"")
        }
        
        let _passOnFocus = false
        if ((ReadDataStorage(REGISTRATION) !== "" ) && (ReadDataStorage(REGISTRATION) !== null)) {
            setRegistration(ReadDataStorage(REGISTRATION))
            setRemember(true)
            _passOnFocus = true
        }
            

        if (isAuthenticated())
            window.location.href = '/'
        else {
            if (_passOnFocus) 
                document.querySelector('#password').focus()
            else
                document.querySelector('#registration').focus()
        }
            
    },[])

    useEffect(() => {
        setStatistic('login')
    } ,[])



    const handleSubmit = async(event) => {
        if (update)
            loadingButton(true,document.querySelector('#update-password'),document.querySelector('#update-password').children[0].classList,document.querySelector('#update-password').lastChild.nodeValue)
        else
            loadingButton(true,document.querySelector('#submit'),document.querySelector('#submit').children[0].classList,document.querySelector('#submit').lastChild.nodeValue)

        event.preventDefault()
        try {
            await api.post('/authenticate', {
                registration,
                password,
                update
            })
                .then(response => {
                    if (response.ok) {
                        if (response.data.update) {
                            setUpdate(true)
                            setPassword('')
                            document.querySelector('#password').focus()
                        } else if(response.data.redirect){ 
                            ShowMessages(response.data.message);
                            setUpdate(false)
                            setPassword('')
                            window.location.href = '/login'
                        } else  {  
                            setUpdate(false)
                            const { token, user, idcompany } = response.data

                            WriteDataStorage(TOKEN_KEY, JSON.stringify(token))
                            WriteDataStorage(USER_DATA, JSON.stringify(user))
                            WriteDataStorage(IDCOMPANY,idcompany)
                            WriteDataStorage(USERNAME, response.data.user.name)                       
                            WriteDataStorage(IDUSER, response.data.user.iduser) 
                            WriteDataStorage(CONTRACT, response.data.hash)  
                            WriteDataStorage(EXPIRES_IN, response.data.expires_in)
                            WriteDataStorage(CONTRACTSTATUS, response.data.idcontractstatus)
                            
                            if (remember === true)
                                WriteDataStorage(REGISTRATION,registration)
                            else 
                                WriteDataStorage(REGISTRATION,'')        
                            window.location.href = '/'
                        }
                    } else {
                        if (update)
                            loadingButton(false,document.querySelector('#update-password'),'fa fa-key','Alterar Senha')
                        else
                            loadingButton(false,document.querySelector('#submit'),'fa fa-sign-in-alt','Acessar')
                    }
                })
        } catch (response) {
            localStorage.clear();
            if (update)
                loadingButton(false,document.querySelector('#update-password'),'fa fa-key','Alterar Senha')
            else
                loadingButton(false,document.querySelector('#submit'),'fa fa-sign-in-alt','Acessar')

            setRegistration('')
            setPassword('')
           // NotificationManager.error("Usuário ou senha Inválido(s)", 'Erro!', 2000);
            //console.log(response)
        }
    }

    return (
        <>
        <div className={`${window.location.href.indexOf('sgecont') > 0 ? 'background-sge' : 'background'}`}>
        </div>
        <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <NotificationContainer />
            <div className="content">
                <div className="block-center wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-blue">
                            <Link to="/login">
                                 {window.location.href.indexOf('sgecont') > 0 ? 
                                     <img className="block-center rounded imgWidth" src={logoSGE} alt={process.env.REACT_APP_NAME} />
                                :
                                     <img className="block-center rounded imgWidth" src={logo} alt={process.env.REACT_APP_NAME} />
                                 }
                               
                            </Link>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", padding: "5px", fontSize: '12px', color: "#cacaca" }}>
                            Versão: {process.env.REACT_APP_VERSION}
                        </div>
                        <div className="card-body">
                            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                                {update === false ? 
                                
                                <div>
                                    <div className="input-group mb-3">
                                        <TextInput
                                            name="registration"
                                            id="registration"
                                            type="text"
                                            placeholder="Usuário"
                                            required
                                            maxLength={60}
                                            errorMessage={{ required: "Por favor, informe um usuário válido!"}}
                                            value={registration}
                                            className="form-control "
                                            onChange={e => setRegistration(e.target.value.toUpperCase())}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="input-group mb-2">
                                        <TextInput
                                            name="password"
                                            id="password"
                                            type="password"
                                            placeholder="Senha"
                                            required
                                            maxLength={20}
                                            errorMessage={{ required: "Por favor, informe uma senha válida!" }}
                                            value={password}
                                            className="form-control"
                                            onChange={e => setPassword(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="input-group mb-4">                              
                                        <div className="checkbox c-checkbox">                                       
                                            <label><input type="checkbox" checked={remember} className="form-check-input" value={remember} onChange={e => setRemember(!remember)}/><span className="fa fa-check"></span>Lembrar minha matrícula</label>
                                        </div>
                                    </div>

                                    <button className="btn btn-block btn-theme mt-3" type="submit" id="submit" style={{width:"100%"}}><em className="fa fa-sign-in-alt"></em> Acessar</button>

                                    <div className="clearfix" style={{marginTop:"15px"}}>
                                        <div className="float-right">
                                            <Link to="/forgot" className="text-muted">Esqueceu sua senha?</Link>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="input-group mb-2">
                                        <TextInput
                                            name="password"
                                            id="password"
                                            type="password"
                                            placeholder="Informe uma nova senha"
                                            required
                                            errorMessage={{ required: "Por favor, informe uma senha válida!",pattern:"A senha devem conter pelo menos 6 dígitos."  }}
                                            value={password}
                                            autoFocus={true}
                                            pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                            className="form-control"
                                            onChange={e => setPassword(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="input-group mb-2">
                                        <TextInput
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            type="password"
                                            placeholder="Repita a nova senha"
                                            required
                                            errorMessage={{ required: "Por favor, informe uma senha válida!",validator : "As senhas informadas não conferem.",pattern:"A senha devem conter pelo menos 6 dígitos."  }}
                                            value={confirmPassword}
                                            validator={matchPassword}
                                            pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                            className="form-control"
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <button className="btn btn-block btn-theme mt-3" type="submit" id="update-password" style={{width:"100%"}}><em className="fa fa-key"></em> Alterar Senha</button>
                                </div>
                            }    
                            </ValidationForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}


