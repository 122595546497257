import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import Loading from '../../../components/Loading';
import CompanyData from './Tabs/companyData';
import FiscalDepartament from './Tabs/fiscalDepartment';
import AccountingDepartament from './Tabs/accountingDepartment';
import AccountManagement from './Tabs/accountManagement';
import PersonalDepartament from './Tabs/personalDepartment';

import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FormRegisterContext } from './formregister-context';
import loadingButton from './../../layouts/Buttons';
import api from '../../../services/api';
import ShowMessages from '../../../components/Exceptions';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

const tabKey = {
    CompanyData: 'CompanyData',
    FiscalDepartament: 'FiscalDepartament',
    AccountingDepartament: 'AccountingDepartament',
    AccountManagement: 'AccountManagement',
    PersonalDepartament: 'PersonalDepartament',
};

export default function ModalFormRegister({
    showModal,
    handleCloseModal,
    editState,
    editData,
    handleReload,
    data,
}) {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabKey.CompanyData);
    const {
        resetFormStates,
        setCompanyData,
        setFiscalDepartament,
        setAccountingDepartament,
        setAccountManagement,
        setPersonalDepartament,
    } = useContext(FormRegisterContext);

    useEffect(() => {
        setActiveTab(tabKey.CompanyData);
    }, [showModal]);

    useEffect(() => {
        if (editState && data) {
            setCompanyData({
                name: data.name,
                integrationdate: data.integrationdate ? (data.integrationdate || '').substring(0, 4) + '-' + (data.integrationdate || '').substring(5, 7) + '-' + (data.integrationdate || '').substring(8, 10) : '',
                entrydate: data.entrydate ?(data.entrydate || '').substring(0, 4) +'-' +(data.entrydate || '').substring(5, 7) +'-' + (data.entrydate || '').substring(8, 10) : '',
                kindofperson: data.kindofperson,
                companyname: data.companyname,
                cpf: data.cpfcnpj,
                cnpj: data.cpfcnpj,
                ie: data.ie,
                im: data.im,
                owner: data.owner,
                address: data.address,
                complement: data.complement,
                number: data.number,
                neighborhood: data.neighborhood,
                cep: data.cep,
                city: data.city,
                uf: data.uf,
                accountingintegrationresponsible: data.accountingintegrationresponsible,
                accountingsupervisionresponsible: data.accountingsupervisionresponsible,
                personalintegrationresponsible: data.personalintegrationresponsible,
                personalsupervisionresponsible: data.personalsupervisionresponsible,
                fiscalintegrationresponsible: data.fiscalintegrationresponsible,
                fiscalsupervisionresponsible: data.fiscalsupervisionresponsible,
                companyestablishmentdate: data.companyestablishmentdate ? (data.companyestablishmentdate || '').substring(0, 4) + '-' +(data.companyestablishmentdate || '').substring(5, 7) + '-' + (data.companyestablishmentdate || '').substring(8, 10) : '',
                activitystartdate : data.activitystartdate ? (data.activitystartdate || '').substring(0, 4) + '-' + (data.activitystartdate || '').substring(5, 7) +'-' + (data.activitystartdate || '').substring(8, 10) : '',
                companytaxation: data.companytaxation,
                comments: data.comments,
            });
            setFiscalDepartament({
                fiscalcompanyname: data.fiscalcompanyname,
                contactperson: data.contactperson,
                contactphone: data.contactphone,
                email: data.email,
                municipality: data.municipality,
                businessactivity: data.businessactivity,
                taxationregime: data.taxationregime,
                assessmentregime: data.assessmentregime,
                hasbranches: data.hasbranches,
                branchquantity: data.branchquantity,
                branchcnpjs: data.branchcnpj,
                digitalcertificate: data.digitalcertificate,
                municipalpassword: data.municipalpassword,
                bidding: data.bidding,
                cndfrequency: data.cndfrequency,
                physicalfiles: data.physicalfiles,
                physicalfilesfrequency: data.physicalfilesfrequency,
                digitalfiles: data.digitalfiles,
                digitalfilesfrequency: data.digitalfilesfrequency,
                rentalcontracts: data.rentalcontracts,
                particularities: data.particularities,
                debtsorinstallments: data.debtsorinstallments,
                openprocesses: data.openprocesses,
                monthlyrevenue: data.monthlyrevenue,
                authenticatedfiscalbooks: data.authenticatedfiscalbooks,
                municipalactiveinstallments: data.municipalactiveinstallments,
                statetaxforecast: data.statetaxforecast,
                statetaxdetails: data.statetaxdetails,
                fiscalbenefits: data.fiscalbenefits,
                fiscalbenefitsdetails: data.fiscalbenefitsdetails,
                taxplanning: data.taxplanning,
                taxplanningstartdate: data.taxplanningstartdate ? (data.taxplanningstartdate || '').substring(0, 4) +'-' +(data.taxplanningstartdate || '').substring(5, 7) +'-' + (data.taxplanningstartdate || '').substring(8, 10) : '',
                piscofinssuspension: data.piscofinssuspension,
                difalsimplenational: data.difalsimplenational,
                activeinstallments: data.activeinstallment,
                observations: data.observations,
            });

            setAccountingDepartament({
                accountingcompanies: data.accountingcompanies,
                currenttaxation: data.currenttaxation,
                previousbalance: data.previousbalance,
                accountingstartdate:data.accountingstartdate ? (data.accountingstartdate || '').substring(0, 4) +'-' +(data.accountingstartdate || '').substring(5, 7) +'-' + (data.accountingstartdate || '').substring(8, 10) : '',
                digitalprocessknowledge: data.digitalprocessknowledge,
                financialresponsible: data.financialresponsible,
                financialsystem: data.financialsystem,
                bankaccounts: data.bankaccounts,
                advancepayments: data.advancepayments,
                inventorycontrol: data.inventorycontrol,
                offbookpayments: data.offbookpayments,
                personalpaymentscontrol: data.personalpaymentscontrol,
                biddingparticipation: data.biddingparticipation,
                financialresultcontrol: data.financialresultcontrol,
                particularitiesaccouting: data.particularitiesaccouting,
                observationdepartament: data.observationdepartament,
            });
            setAccountManagement({
                digitalcertificatemanagement: data.digitalcertificatemanagement,
                terminationwithpreviousaccounting:
                    data.terminationwithpreviousaccounting,
                ceipresence: data.ceipresence,
                domesticemployees: data.domesticemployees,
                firedepartmentcompliance: data.firedepartmentcompliance,
                permits: data.permits,
                observationmanagement: data.observationmanagement,
            });
            setPersonalDepartament({
                outsidepayroll: data.outsidepayroll,
                ppra_pcmso: data.ppra_pcmso,
                departmentresponsible: data.departmentresponsible,
                prolaborewithdrawal: data.prolaborewithdrawal,
                fgtsuptodate: data.fgtsuptodate,
                previdenciarydebts: data.previdenciarydebts,
                previdenciaryinstallment: data.previdenciaryinstallment,
                insscredits: data.insscredits,
                desoneration: data.desoneration,
                businessactivityandunion: data.businessactivityandunion,
                payrollclosingdate: data.payrollclosingdate ? (data.payrollclosingdate || '').substring(0, 4) +'-' + (data.payrollclosingdate || '').substring(5, 7) +'-' +(data.payrollclosingdate || '').substring(8, 10) : '',
                numberofemployees: data.numberofemployees,
                observationpersonal: data.observationpersonal,
            });
        }
    }, [editState, data]);

    const handleSubmit = async (event, data) => {
        event.preventDefault();
        loadingButton(
            true,
            document.querySelector('#submit-form-register'),
            document.querySelector('#submit-form-register').children[0].classList,
            document.querySelector('#submit-form-register').lastChild.nodeValue,
        );
        try {
            let _data = { data };

            if (editState)
                await api
                    .put('/client-formdata/' + editData.idclientform, _data)
                    .then((response) => {
                        ShowMessages(response);
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal();
                                handleReload(true);
                                resetFormStates();
                            } else
                                loadingButton(
                                    false,
                                    document.querySelector('#submit-form-register'),
                                );
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-form-register'),
                            );
                    })
                    .catch((error) => {
                        loadingButton(
                            false,
                            document.querySelector('#submit-form-register'),
                        );
                        ShowMessages(error);
                    });
            else
                await api
                    .post('/client-formdata', _data)
                    .then((response) => {
                        ShowMessages(response);
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal();
                                handleReload(true);
                                resetFormStates();
                            } else
                                loadingButton(
                                    false,
                                    document.querySelector('#submit-form-register'),
                                );
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-form-register'),
                            );
                    })
                    .catch((error) => {
                        loadingButton(
                            false,
                            document.querySelector('#submit-form-register'),
                        );
                        ShowMessages(error);
                    });
        } catch (response) {
            loadingButton(false, document.querySelector('#submit-form-register'));
            ShowMessages(response);
        }
    };

    const handleClose = () => {
        resetFormStates();
        handleCloseModal();
    };

    return (
        <Modal
            size="lg"
            dialogClassName="width500 height550"
            show={showModal}
            onHide={handleCloseModal}
            animation={true}
            backdrop="static"
            dialogAs={DraggableModalDialog}
            style={{
                width: 'calc(100% + 80px)',
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ marginBottom: '0px' }}>
                    <i className="fa fa-user-friends"></i> Formulário de Cadastro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Loading loading={loading} addclassName="heigth330px" />
                ) : null}

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === tabKey.CompanyData ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(tabKey.CompanyData);
                            }}
                            style={{
                                padding: '10px 16px',
                            }}
                        >
                            <span id="personal-data">Dados da Empresa</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === tabKey.FiscalDepartament ? 'active' : ''}
                            onClick={() => setActiveTab(tabKey.FiscalDepartament)}
                            style={{
                                padding: '10px 16px',
                            }}
                        >
                            <span id="documents">Departamento Fiscal</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                activeTab === tabKey.AccountingDepartament ? 'active' : ''
                            }
                            onClick={() => setActiveTab(tabKey.AccountingDepartament)}
                            style={{
                                padding: '10px 16px',
                            }}
                        >
                            <span id="address-phones">Departamento Contábil</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === tabKey.AccountManagement ? 'active' : ''}
                            onClick={() => setActiveTab(tabKey.AccountManagement)}
                            style={{
                                padding: '10px 16px',
                            }}
                        >
                            <span id="other-contacts">Gestão De Contas</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                activeTab === tabKey.PersonalDepartament ? 'active' : ''
                            }
                            onClick={() => setActiveTab(tabKey.PersonalDepartament)}
                            style={{
                                padding: '10px 16px',
                            }}
                        >
                            <span id="professional">Departamento Pessoal</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ padding: '5px' }}>
                    <TabPane tabId={tabKey.CompanyData}>
                        <div style={{ display: 'flex' }}>
                            <CompanyData
                                loading={loading}
                                handleCloseModal={handleCloseModal}
                            />
                        </div>
                    </TabPane>

                    <TabPane tabId={tabKey.FiscalDepartament}>
                        <FiscalDepartament handleCloseModal={handleCloseModal} />
                    </TabPane>

                    <TabPane tabId={tabKey.AccountingDepartament}>
                        <AccountingDepartament />
                    </TabPane>

                    <TabPane tabId={tabKey.AccountManagement}>
                        <AccountManagement handleCloseModal={handleCloseModal} />
                    </TabPane>

                    <TabPane tabId={tabKey.PersonalDepartament}>
                        <PersonalDepartament
                            handleCloseModal={handleCloseModal}
                            loading={loading}
                        />
                    </TabPane>
                </TabContent>
            </Modal.Body>
            <FormRegisterContext.Consumer>
                {(data) => (
                    <Modal.Footer>
                        <Button
                            type="submit"
                            id="submit-form-register"
                            className={`btn-theme ${loading ? 'hidden' : ''}`}
                            onClick={(e) => {
                                handleSubmit(e, data);
                            }}
                        >
                            <em className="fa fa-save"></em> Salvar
                        </Button>
                        <Button className="btn-ligth-gray" onClick={handleClose}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </Modal.Footer>
                )}
            </FormRegisterContext.Consumer>
        </Modal>
    );
}
