import { createContext, useContext, useState } from 'react';

export const FormRegisterContext = createContext();

const companyDataInitial = {
  name: '',
  integrationdate: '',
  entrydate: '',
  kindofperson: '',
  companyname: '',
  cpf: '',
  cnpj: '',
  ie: '',
  im: '',
  owner: '',
  address: '',
  complement: '',
  number: '',
  neighborhood: '',
  cep: '',
  city: '',
  uf: '',
  accountingintegrationresponsible: '',
  accountingsupervisionresponsible: '',
  personalintegrationresponsible: '',
  personalsupervisionresponsible: '',
  fiscalintegrationresponsible: '',
  fiscalsupervisionresponsible: '',
  companyestablishmentdate: '',
  activitystartdate: '',
  companytaxation: '',
  comments: '',
};

const fiscalDepartamentInitial = {
  fiscalcompanyname: '',
  contactperson: '',
  contactphone: '',
  email: '',
  municipality: '',
  businessactivity: '',
  taxationregime: '',
  assessmentregime: '',
  hasbranches: '',
  branchquantity: '',
  branchcnpjs: [],
  digitalcertificate: '',
  municipalpassword: '',
  bidding: '',
  cndfrequency: '',
  physicalfiles: '',
  physicalfilesfrequency: '',
  digitalfiles: '',
  digitalfilesfrequency: '',
  rentalcontracts: '',
  particularities: '',
  debtsorinstallments: '',
  openprocesses: '',
  monthlyrevenue: '',
  authenticatedfiscalbooks: '',
  municipalactiveinstallments: '',
  activeinstallments: '',
  statetaxforecast: '',
  statetaxdetails: '',
  fiscalbenefits: '',
  fiscalbenefitsdetails: '',
  taxplanning: '',
  taxplanningstartdate: '',
  piscofinssuspension: '',
  difalsimplenational: '',
  activeinstallment: '',
  observations: '',
};

const accountingDepartamentInitial = {
  accountingcompanies: '',
  currenttaxation: '',
  previousbalance: '',
  accountingstartdate: '',
  digitalprocessknowledge: '',
  financialresponsible: '',
  financialsystem: '',
  bankaccounts: '',
  advancepayments: '',
  inventorycontrol: '',
  offbookpayments: '',
  personalpaymentscontrol: '',
  biddingparticipation: '',
  financialresultcontrol: '',
  particularitiesaccounting: '',
  observationdepartament: '',
};

const accountManagementInitial = {
  digitalcertificatemanagement: '',
  terminationwithpreviousaccounting: '',
  ceipresence: '',
  domesticemployees: '',
  firedepartmentcompliance: '',
  permits: '',
  observationmanagement: '',
};

const personalDepartamentInitial = {
  outsidepayroll: '',
  ppra_pcmso: '',
  departmentresponsible: '',
  prolaborewithdrawal: '',
  fgtsuptodate: '',
  previdenciarydebts: '',
  previdenciaryinstallment: '',
  insscredits: '',
  desoneration: '',
  businessactivityandunion: '',
  payrollclosingdate: '',
  numberofemployees: '',
  observationpersonal: '',
};

export const FormRegisterProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(companyDataInitial);
  const [fiscalDepartament, setFiscalDepartament] = useState(
    fiscalDepartamentInitial,
  );
  const [accountingDepartament, setAccountingDepartament] = useState(
    accountingDepartamentInitial,
  );
  const [accountManagement, setAccountManagement] = useState(
    accountManagementInitial,
  );
  const [personalDepartament, setPersonalDepartament] = useState(
    personalDepartamentInitial,
  );

  const resetFormStates = () => {
    setCompanyData(companyDataInitial);
    setFiscalDepartament(fiscalDepartamentInitial);
    setAccountingDepartament(accountingDepartamentInitial);
    setAccountManagement(accountManagementInitial);
    setPersonalDepartament(personalDepartamentInitial);
  };

  return (
    <FormRegisterContext.Provider
      value={{
        companyData,
        setCompanyData,
        fiscalDepartament,
        setFiscalDepartament,
        accountingDepartament,
        setAccountingDepartament,
        accountManagement,
        setAccountManagement,
        personalDepartament,
        setPersonalDepartament,
        resetFormStates,
      }}
    >
      {children}
    </FormRegisterContext.Provider>
  );
};

export const useFormRegister = () => {
  return useContext(FormRegisterContext);
};
