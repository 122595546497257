import React, {useState, useEffect} from 'react'

import { IDCOMPANY, ReadDataStorage} from './../../services/auth'
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import Swal from 'sweetalert2'
import Loading from '../../components/Loading';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import MaskWithValidation from './../../components/maskInput';
import IMG_QRCODE from './../../assets/qrcode.gif'
import IMG_WHATSAPP_LOADING from './../../assets/whatsapp-loading.gif'
import IMG_WHATSAPP from './../../assets/whatsapp-logo.png'

import { v4 as uuidv4 }  from 'uuid'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

let interval = null

export default function ModalMyNumberConnect({showModal, handleCloseModal, handleReload, data, editState}) {
    const [loadingQR, setLoadingQR]            = useState(false)
    const [loading, setLoading]                = useState(false)

    const [number, setNumber]                  = useState('')
    const [isLogin, setIsLogin]                = useState(false)

    const [userData, setUserData]              = useState('')

    const [name, setName]                      = useState('')
    const [whatsapp, setWhatsapp]              = useState('')
    const [platform, setPlatform]              = useState('')

    const [numberTest, setNumberTest]          = useState('')
    const [error, setError]                    = useState('')
    const [hash, setHash]                      = useState('')

    const [QR_CODE, setQrCode]                 = useState(IMG_QRCODE)
    const [loadingMessage, setLoadingMessage]  = useState('')

    const sendMessage = async() => {
        if (numberTest === '') {
            ShowMessages({message : "Informe o número do destinatário de mensagem.", status : 500})
            return
        }

        if (number === '')
            setNumber(whatsapp.replace(/[\W]+/g,""))

        await api.post('/api/v1/sendMessage',{number : (number || whatsapp).replace(/[\W]+/g,""),
                                              sender : numberTest.replace(/[\W]+/g,""),
                                              hash,
                                              bodyMessage : "Mensagem de teste da VIROMA Tecnologia."})
                 .then((response) => {
                    if (response.data.data.sent)
                        ShowMessages({message : "Mensagem enviada com sucesso!",status : 200})
                    else 
                        ShowMessages({message : "Erro ao enviar sua mensagem!",status : 500})
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    const disconnect = async() => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desconectar do WhatsApp",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/api/v1/logout',{number : number.replace(/[\W]+/g,"")})
                         .then((response) => {
                            console.log(response.data)
                            if (!response.data.data.auth)
                                ShowMessages({message : response.data.data.message,status : 200})

                            handleReload()
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const getQRCode = async() => {
        let _userData = ''
        if (userData === '') {
            let _userData = ReadDataStorage(IDCOMPANY) + '-' + uuidv4()
            setUserData(_userData)
        } else
            _userData = userData

        if ((document.querySelectorAll('.box-shadow').length > 0) && (_userData !== undefined) && (_userData !== '')){
            //setLoading(true)
            await api.get('/api/v1/auth/' + _userData)
                        .then((response) => {
                                if (response.data?.status) {
                                    if (response.data.status === 504) {
                                        setError('Erro interno do sistema: API TIMEOUT')
                                        setUserData('')
                                        _userData = ''
                                    } else {
                                        setIsLogin(response.data.data?.auth)                    
                                        if (response.data.data?.auth) {
                                            setName(response.data.data.info?.pushname)
                                            let _phone = response.data.data.info?.user
                                            setPlatform(response.data.data.info?.platform)
                                            setHash(response.data.data.environment)
                                            setWhatsapp('+' + _phone.toString().substr(0,2) + ' (' + _phone.toString().substr(2,2) + ') ' + ((_phone.toString().substr(4,4) === 3) ? '' : '9' + _phone.toString().substr(4,4)) + '-' + _phone.toString().substr(8,4))
                                            setLoadingQR(false)
                                            clearInterval(interval)
                                            handleReload()
                                            interval = null
                                            setUserData('')
                                            _userData = ''
                                            setNumber(_phone)
                                        } else { 
                                            if (response.data.data?.message) {
                                                setError(response.data.data.message)
                                                setQrCode(IMG_QRCODE)
                                            } else if (((response.data?.data?.base64img === '') || (response.data?.data?.base64img === undefined)) && (response.data?.data?.loading <= 0)) {
                                                if (interval === null) {
                                                    interval = setInterval(() => {             
                                                        getQRCode() 
                                                    }, 3000);                          
                                                }
                                                setError(null)
                                                setQrCode(IMG_QRCODE)
                                            } else {
                                                setError(null)
                                                if (response.data?.data?.loading <= 0) {
                                                    setQrCode(response.data.data.base64img)
                                                    setLoadingMessage('')
                                                } else {
                                                    setQrCode(IMG_WHATSAPP_LOADING)
                                                    setLoadingMessage(response.data?.data?.loadingmessage)
                                                }
                                                
                                                setLoadingQR(false)
                                                if (interval === null) {
                                                    interval =  setInterval(() => {             
                                                        getQRCode() 
                                                    }, 3000);                          
                                                }
                                            }
                                            setName('')
                                            setPlatform('')
                                            setWhatsapp('')
                                            setLoadingQR(false)
                                        } 
                                    } 
                                } else { 
                                    if (interval === null) {
                                        interval = setInterval(() => {             
                                            getQRCode() 
                                        }, 3000);                          
                                    }
                                }
                                setLoading(false) 
                            })
                          .catch( error => { 
                                setLoading(false)
                                setLoadingQR(false)             
                                setIsLogin(false)
                                setQrCode(QR_CODE)
                                console.log(error)
                            })
        }                        
    }

    useEffect(() => {
        if (editState) {
            setNumber(data.number)
            setUserData(data.environment)
        } else {
            setNumber('')
            setUserData('') 
        }
       
        if (!showModal) {
            clearInterval(interval)
            interval = null
        }    

        getQRCode()
        
    },[interval,showModal,data])

    useEffect(() => {
        setStatistic('modals\\modal-mynumber-connect')
    } ,[])

    return (
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550"  dialogAs={DraggableModalDialog}>
            <Modal.Header closeButton>
                <Modal.Title style={{marginBottom:"0px"}}>
                    <i className="fab fa-whatsapp"></i> Status de Conexão
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <ValidationForm>
                <div>   
                    <Loading loading={loading}/>
                    <div className={`box-shadow ${(loading ? "hidden" : "")}`} style={{padding:"15px"}}>      
                        {isLogin ?   
                            <>
                                <div className="bg-success" style={{padding:"15px", borderRadius:"5px"}}>
                                    <div>
                                        <span style={{fontWeight:"bold"}}>No momento você encontra-se conectado, abaixo está o seus dados de conexão.</span>
                                    </div>
                                    <div style={{display:"flex", marginTop:'30px'}}>
                                        <div>
                                            <img alt="" src={IMG_WHATSAPP} style={{width:"75px"}}/>
                                        </div>
                                        <div style={{marginLeft:"30px", display:"flex",justifyContent:"center", flexDirection:"column"}}>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Nome:</span> {name}
                                            </div>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Telefone:</span> {whatsapp}
                                            </div>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Plataforma:</span> {platform}
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div style={{height:"120px", display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <div >
                                        <button className="btn btn-danger" onClick={e => disconnect(e)}><i className='fa fa-power-off' /> Desconectar do WhatsApp</button>
                                    </div>
                                    
                                    <div style={{borderLeft:"1px dashed #cacaca", marginLeft:"15px"}}>
                                        <div style={{marginLeft:"15px"}}>
                                            <div className="input-group">
                                                <label>Número do Destinatário</label>
                                                <MaskWithValidation
                                                    name="numberTest"
                                                    id="numberTest"
                                                    maxLength={25}
                                                    value={numberTest}
                                                    required
                                                    errorMessage={{ required: "Por favor, informe um número!" }}
                                                    className="form-control"
                                                    onChange={e =>  setNumberTest(e.target.value)}
                                                    autoComplete="off"
                                                    mask={[/[0-9]/,/[0-9]/,' ','(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}/>
                                            </div>
                                            <button type="button" className="btn btn-info" style={{marginTop:"5px"}} onClick={e => sendMessage()}><i className='fa fa-paper-plane'/> Enviar Mensagem de Teste</button>
                                        </div>                                        
                                    </div>  

                                </div>
                            </>
                        :
                            <>
                                {error ? 
                                    <div className="bg-danger" style={{padding:"15px", borderRadius:"5px", textAlign:"center"}}>
                                        {error}
                                    </div>
                                : (loadingMessage === '') ? 
                                        <div className="bg-info" style={{padding:"15px", borderRadius:"5px"}}>
                                            O número <span style={{fontWeight:"bold"}}>{number}</span> ainda não está conectado, abra o WhatsApp do seu celular, e scaneie o QR Code abaixo!
                                        </div>
                                    : ''
                                }
                                <div style={{height:"300px", display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                    <img src={QR_CODE} style={{maxWidth:"350px"}}/>
                                    {loadingMessage !== '' ?
                                        <div style={{marginTop:"15px", display:"flex",justifyContent:"center",width:"100%"}}>
                                            <span style={{fontSize:"18px", color:"#27c24c"}}>{loadingMessage}</span>
                                        </div>
                                    : ''}

                                </div>
                            </>        
                        }                            
                    </div>
                </div>
                </ValidationForm>
            </Modal.Body>
            <Modal.Footer>  
                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}