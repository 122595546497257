import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import { Button } from 'reactstrap';

import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import ModalPDFViewer from "./../../pages/modals/modal-pdf-viewer"

import { format, startOfMonth , endOfMonth  } from 'date-fns'
import {checkPermission} from './../../components/security'

export default function Commission(props) {
    const [loading, setLoading]                 = useState(false)

    const [startDate, setStartDate]             = useState(format(startOfMonth(new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                 = useState(format(endOfMonth(new Date()),'yyyy-MM-dd'))
    const [employer, setEmployer]               = useState(0)
    const [service, setService]                 = useState(0)
    const [departament, setDepartament]         = useState(0)
    const [type, setType]                       = useState(0)
    const [showValues, setShowValues]           = useState(true)

    const [colaboradorData, setColaboradorData] = useState([])
    const [servicoData, setServicoData]         = useState([])
    const [departamentData, setDepartamentData] = useState([])

    const [print, setPrint]                     = useState(false)
    const [url, setURL]                         = useState('')

    const getData = async() => {
        await api.get('/getCommissionData')
                 .then(response => {
                    setColaboradorData(response.data._colaboradores || [])
                    setServicoData(response.data._services || [])
                    setDepartamentData(response.data._departament || [])
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const handleSubmit = async() => {
        setURL('/showReportCommission/' + startDate + '/' + endDate + '/' + type + '/' + showValues + '/' + employer + '/' + service + '/' + departament)
        setPrint(true)
  
    }

    useEffect(() => {
        getData()
    },[])

    return (    
        <ContentWrapper>
            <ModalPDFViewer showModal={print} handleCloseModal={setPrint} url={url}/>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"80vh", flexDirection:'column'}}>              
                <div style={{width:"30%",border: "1px solid #cacaca", borderRadius: "5px", padding:"15px"}}>
                    <div style={{width:"100%", marginTop:"-30px"}}> 
                        <span style={{fontSize:"18px",fontWeight:"bold", background:"#fff"}}>Configurações de Relatório</span>
                    </div>
                    <div style={{display:"flex"}}>
                        <div>
                            <label>Data Inicial</label>
                            <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                        </div>
                        <div style={{marginLeft:"15px"}}>
                            <label>Data Final</label>
                            <input type="date" className='form-control'value={endDate} onChange={e => setEndDate(e.target.value)}/>
                        </div>
                    </div>
                    {checkPermission(2,46) ? 
                        <div style={{display:"flex", marginTop:"10px"}}>
                            <div style={{width:"100%"}}>
                                <label>Colaborador</label>
                                <select className="form-select" value={employer} onChange={e => setEmployer(e.target.value)}>
                                    <option value="0">Todos</option>
                                    {colaboradorData.map((item, key) => {
                                        return <option key={key} value={item.cod}>{item.nom}</option>
                                    })}
                                </select>
                            </div>
                        </div>  
                    : ""
                    }
                    <div style={{display:"flex", marginTop:"10px"}}>
                            <div style={{width:"100%"}}>
                                <label>Departamento</label>
                                <select className="form-select" value={departament} onChange={e => setDepartament(e.target.value)}>
                                    <option value="0">Todos</option>
                                    {departamentData.map((item, key) => {
                                        return <option key={key} value={item.iddepartament  }>{item.departament}</option>
                                    })}
                                </select>
                            </div>
                        </div> 
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"100%"}}>
                            <label>Serviço</label>
                            <select className="form-select" value={service} onChange={e => setService(e.target.value)}>
                            <option value="0">Todos</option>
                                {servicoData.map((item, key) => {
                                    return <option key={key} value={item.cod}>{item.commission}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{marginTop:"15px",display:"flex"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <input type="radio" checked={type === 0} onChange={e => setType(0)}/>
                            <label style={{marginLeft:"7px"}}>Analítico</label>
                        </div>
                        <div style={{marginLeft:"25px", display:"flex", alignItems:"center"}}>
                            <input type="radio" checked={type === 1} onChange={e => setType(1)}/>
                            <label style={{marginLeft:"7px"}}>Sintético</label>
                        </div>
                        {checkPermission(4,46) ? 
                        <div style={{marginLeft:"25px", display:"flex", borderLeft:"1px dashed #cacaca"}}>
                            <label className="switch switch-sm" style={{ justifyContent: "center", marginLeft:"15px" }}>
                                <input type="checkbox" checked={showValues} value={showValues}   onChange={e => setShowValues(!showValues)}/>
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <span style={{marginLeft:"7px", marginTop:"5px"}}>Mostrar valores dos emolumentos?</span>
                        </div>
                        : ""}
                    </div>
                </div>                   
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button type="submit" className={`btn-theme ${loading ? "hidden" : ""}`} onClick={e => handleSubmit()}>
                    <em className="fa fa-print"></em> Gerar Relatório
                    </Button>
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}