import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalCSATResult from '../modals/modal-csat-result';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'


import { format   }        from 'date-fns'

export default function CSATResult() {
    const [loading, setLoading]                            = useState(false)
    const [loadingAllPrint, setLoadingAllPrint]            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(new Date(),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true   
                                                                       }])
    const [editPermission, setEditPermisson]               = useState(false)
    const [startDate, setStartDate]                        = useState(format(new Date(),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [statusMessages, setStatusMessages]              = useState(-99)

    const [dataStatus, setDataStatus]                      = useState([])

    const handleSearch = async() => {
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true   
        }]

        if (statusMessages !== -99)
            _filters.push({
            field      : 'idstatuscsat',
            key        : '=',  
            value      : statusMessages,
        })

        setFilters(_filters)
    }

    const getCSat = async() => {
        await api.get('/getCSatStatus/0')
                 .then(response => {
                    let _editCheck = !checkPermission(4,53)
                    setEditPermisson(_editCheck)
                    setDataStatus(response.data)
                    setGridParams({idcsat     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                              value : '00000'},
                                                 primaryKey  : true                          },
                                   create_at  : {text        : "Data"},
                                   name       : {text        : "Nome",
                                                 searchDefault : true},
                                   phone_format: {text        : 'Número'},
                                   key         : {text        : 'Protocolo'},
                                   statuscsat  : {text        : "Status",
                                   item        : {className : "text-center",
                                                  switch    : {data  : response.data,
                                                               key   : 'idstatuscsat'}}},
                                   Options    : {text        : "Opções",
                                                 className   : "text-center",
                                                 order       : false,
                                                 filter      : false,
                                                 item        : [ {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Visualizar e avaliar pesquisa",
                                                                           disabled       : _editCheck,
                                                                           className      : 'btn btn-sm btn-warning',
                                                                           icon           : "fa fa-edit",
                                                                           indexcallback  : 0}},
                                                                {type  : "Custom",
                                                                 props : {tag            : "button",
                                                                          title          : "Impressão da pesquisa",
                                                                          disabled       : _editCheck,
                                                                          className      : 'btn btn-sm btn-secondary',
                                                                          icon           : "fa fa-print",
                                                                          indexcallback  : 1}}]}
                                             
                    })
                 }).catch(e => {
                    console.log(e)
                 })  
    } 

    useEffect(() => {
        getCSat()
        setStatistic('maintenance\\csat-result')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const EditCSAT = async(id) => {
        await api.get('/csat-result/' + id)
                 .then(response => {
                    console.log(response.data)
                    setData(response.data)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const Printer = async(id) => {
        await api.post('/csat-result/print/' + id)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = id + '.pdf';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const AllPrinter = async() => {
        let _filters = ''
        if ((filters !== "") && (JSON.stringify(filters) !== "[]")) 
            _filters = btoa(JSON.stringify(filters))
        setLoadingAllPrint(true)
        await api.post('/csat-result/allprint/' + _filters)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = 'CSAT.pdf';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                    setLoadingAllPrint(false)
                 })
                 .catch( error => { 
                    setLoadingAllPrint(false)
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalCSATResult showModal={showModal} handleCloseModal = {handleCloseModal} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,5)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                    <button className="btn btn-warning" disabled={loadingAllPrint} title="Exportação Completa" style={{marginLeft:"3px"}} onClick={e => AllPrinter()}><i className={`fa ${loadingAllPrint ? 'fa-spin fa-spinner' : 'fa-file-arrow-down'}`}></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                    <div style={{borderLeft:"1px solid #cacaca", marginLeft:"15px", display:"flex"}}>
                            <div style={{marginLeft:"15px", width:"135px"}}>
                                <span>Data Inicial</span>
                                <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px", width:"135px"}}>
                                <span>Data Final</span>
                                <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{ marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                            <label>Status da Mensagem</label>
                            <select className="form-select" value={statusMessages} onChange={e => setStatusMessages(parseInt(e.target.value))}>
                                <option value={-99} selected>Todos</option>
                                {dataStatus.map((item) => {
                                    return(<option value={item.idstatuscsat}>{item.statuscsat}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{marginLeft:"15px", display:"flex",alignItems:"center"}}>
                            <button className="btn btn-success" title="Clique aqui para pesquisar" onClick={e => handleSearch(e)}><i className="fa fa-search"/></button>
                        </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/csat-result' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "CSAT"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {editPermission ? undefined : EditCSAT}
                      callbackButtons     = {[EditCSAT,
                                              Printer]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}