import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'
import setStatistic from './../../services/apiStatistic'
import { FullScreen, useFullScreenHandle } from "react-full-screen";



export default function BIReport({ idmenu}) {
    const [loading, setLoading]  = useState(false)
    const [link, setLink]        = useState('')
    const [maximized, setMaximized] = useState(false)
    const handle = useFullScreenHandle();

    const getBI = async() => {
        setLoading(true)
        await api.get('/link-bi-menu/' + (idmenu || window.location.pathname.split('/')[2]))
        .then(response => {
           setLink(response.data[0].link)
           setLoading(false)
        })
        .catch( error => {
           ShowMessages(error)
        })
    }

    const toggleShowHide = () => {
        setMaximized(true)
        document.querySelector('.rc-tabs-nav-wrap')?.classList.add('display-none');
        document.querySelector('.topnavbar-wrapper')?.classList.add('display-none');
        document.querySelector('.aside-inner')?.classList.add('hide-aside-margin');
        document.querySelector('.aside-container')?.classList.add('hide-aside-margin');
        document.querySelector('.aside-container')?.classList.add('hide-aside-width');
        document.querySelector('.section-container')?.classList.add('hide-aside-margin');
        document.querySelector('.aside-inner')?.classList.add('hide-aside-width');
        document.querySelector('.profile-wrap')?.classList.add('hide-aside-margin-90');
        document.querySelector('.profile-wrap')?.classList.add('hide-aside-width');
    }

    const handleFullScreen = (e) => {   
              toggleShowHide(); 
              //resize()
       // toggleCollapsed(); 
        // setShowMenu(false)
        //handle.enter()
        
    }

    const toggleCollapsed = () => {
        setMaximized(false)
        document.querySelector('.rc-tabs-nav-wrap')?.classList.remove('display-none');
        document.querySelector('.topnavbar-wrapper')?.classList.remove('display-none');
        document.querySelector('.aside-inner')?.classList.remove('hide-aside-margin');
        document.querySelector('.aside-container')?.classList.remove('hide-aside-margin');
        document.querySelector('.aside-container')?.classList.remove('hide-aside-width');
        document.querySelector('.section-container')?.classList.remove('hide-aside-margin');
        document.querySelector('.aside-inner')?.classList.remove('hide-aside-width');
        document.querySelector('.profile-wrap')?.classList.remove('hide-aside-margin-90');
        document.querySelector('.profile-wrap')?.classList.remove('hide-aside-width');
      //  if(window.height > 1000){
            document.querySelector('body')?.classList.toggle('aside-collapsed')
            resize()
        //}
    }

    const resize = () => {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    }
   
    useEffect(() => {
        if (window.location.pathname.split('/')[1] !== 'bireport') {
            window.open(window.location.origin + '/bireport/' + idmenu,'_blank')
        } else
            handleFullScreen(this)
        getBI()
        
        setStatistic('maintenance\\bireport')
    },[])


    return (    
        <FullScreen handle={handle}>
        <   ContentWrapper>
                <Loading loading={loading}/>
                <div style={{display:"flex", flex:1, flexDirection:"column"}}>
                    <div style={{display:"flex", justifyContent:"flex-end", padding:"5px", position:"absolute", right:"0px"}}>
                        {maximized ?
                            <button className='btn btn-ligth btn-sm' onClick={e => toggleCollapsed()}><i className='fa fa-xmark' style={{fontSize:"18px"}}/></button>
                        : 
                            <button className='btn btn-ligth btn-sm' onClick={e => toggleShowHide()} style={{paddingLeft:"5px"}}><i className='fa fa-expand' style={{fontSize:"18px"}}/></button>
                        }
                    </div>

                    <iframe style={{width:100 + '%', height:"100vh"}} src={link} frameBorder="0" allowFullScreen={true}></iframe>   
                </div>


            </ContentWrapper>
        </FullScreen>

    )
}