import React from 'react';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormRegister } from '../formregister-context';

export default function PersonalDepartament() {
  const { personalDepartament, setPersonalDepartament } = useFormRegister();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalDepartament((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div
      style={{
        maxHeight: '330px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '5px',
      }}
    >
      <ValidationForm>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label className="mt-1">
                Existe folha por fora? Como é controlado?
              </Form.Label>
              <Form.Control
                type="text"
                name="outsidepayroll"
                value={personalDepartament.outsidepayroll}
                onChange={handleChange}
                className="mb-2 mt-2"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                A Empresa possui os programas PPRA E PCMSO? O programa está em
                dias?
              </Form.Label>
              <Form.Control
                type="text"
                name="ppra_pcmso"
                value={personalDepartament.ppra_pcmso}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={7}>
            <Form.Group>
              <Form.Label>
                Existe alguém responsável pelo departamento? Se sim, nome e
                e-mail.
              </Form.Label>
              <Form.Control
                type="text"
                name="departmentresponsible"
                value={personalDepartament.departmentresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group>
              <Form.Label>Existe retirada de pró-labore na folha?</Form.Label>
              <Form.Control
                type="text"
                name="prolaborewithdrawal"
                value={personalDepartament.prolaborewithdrawal}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={5}>
            <Form.Group>
              <Form.Label>FGTS está em dias?</Form.Label>
              <Form.Control
                type="text"
                name="fgtsuptodate"
                value={personalDepartament.fgtsuptodate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={7}>
            <Form.Group>
              <Form.Label>A Empresa possui débitos previdenciários?</Form.Label>
              <Form.Control
                type="text"
                name="previdenciarydebts"
                value={personalDepartament.previdenciarydebts}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={5}>
            <Form.Group>
              <Form.Label>
                A Empresa possui Parcelamento previdenciário?
              </Form.Label>
              <Form.Control
                type="text"
                name="previdenciaryinstallment"
                value={personalDepartament.previdenciaryinstallment}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={7}>
            <Form.Group>
              <Form.Label>
                A Empresa possui créditos de INSS? Existe retenção de INSS em
                NF?
              </Form.Label>
              <Form.Control
                type="text"
                name="insscredits"
                value={personalDepartament.insscredits}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Desoneração?</Form.Label>
              <Form.Control
                type="text"
                name="desoneration"
                value={personalDepartament.desoneration}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Ramo de atividade e sindicato da empresa.</Form.Label>
              <Form.Control
                type="text"
                name="businessactivityandunion"
                value={personalDepartament.businessactivityandunion}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Data fechamento da folha:</Form.Label>
              <Form.Control
                type="date"
                name="payrollclosingdate"
                value={personalDepartament.payrollclosingdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Group>
              <Form.Label>Quantidade de colaboradores.</Form.Label>
              <Form.Control
                type="text"
                name="numberofemployees"
                value={personalDepartament.numberofemployees}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>OBSERVAÇÃO</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="observationpersonal"
                value={personalDepartament.observationpersonal}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </ValidationForm>
    </div>
  );
}
