import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import { format   }        from 'date-fns'

export default function HistoryMessages() {
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(new Date(),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true   
                                                                       }])
    const [dataStatus, setDataStatus]                      = useState([])
    const [startDate, setStartDate]                        = useState(format(new Date(),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [statusMessages, setStatusMessages]              = useState(-99)
    const [messageType, setMessageType]                    = useState(-1)
    const [numberType, setNumberType]                      = useState(-1)
    const [dataMessageType, setDataMessageType]            = useState([])

    const handleSearch = async() => {
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true   
                        }]
               
        if (statusMessages !== -99)
            _filters.push({
                field      : 'idstatusmessage',
                key        : '=',  
                value      : statusMessages,
            })

        if (messageType !== -1)
            _filters.push({
                field      : 'idmessagewhatstype',
                key        : '=',  
                value      : messageType,
            })

        if (numberType !== -1)
            _filters.push({
                field      : 'numbertype',
                key        : '=',  
                value      : numberType,
            })

        setFilters(_filters)
    }

    const getData = async() => {
        await api.get('/message-whats-type')
                 .then(response => {
                    setDataMessageType(response.data)
                                    
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const getHistory = async() => {
        await api.get('/statusmessage/0')
                 .then(response => {
                      setDataStatus(response.data)
                      setGridParams({idhistorymessage : {text        : "#ID", mask : { name  : 'leftSlice',
                                                                                       value : '00000'},
                                                         primaryKey   : true,
                                                         visible      : false        },
                                     create_at        : {text        : "Data do Envio",
                                                         dataType    : "date",
                                                         tableName   : 'history_messages',
                                                         ignoretime  : true},
                                     phone_format      : {text        : "Whatsapp de Destino"},
                                     name             : {text        : "Nome do Destinatário",                    
                                                         searchDefault : true},
                                     key              :  {text       : 'Protocolo'},
                                     messagewhatstype : {text        : "Tipo de Alerta"},
                                     statusmessages   : {text        : "Status Envio",
                                                         item        : {className : "text-center",
                                                         switch      : {data  : response.data,
                                                                        key   : 'idstatusmessage'}}},
                                     detail           : {text        : "Tipo de Mensagem"},
                                     message          : {text        : "Mensagem",
                                                         props       : {maxLength   : 30,
                                                                        fieldTitle   : true}},
                                     errormessage     : {text        : "Mensagem de Erro",
                                                         props       : {maxLength   : 60}}
                                             
                      })
                 })
                .catch( error => { 
                    ShowMessages(error)
                })
    }

    useEffect(() => {
        handleSearch()
        getHistory()
        getData()
        setStatistic('maintenance\\history-messages')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(2,22)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                        <div style={{borderLeft:"1px solid #cacaca", marginLeft:"15px", display:"flex"}}>
                            <div style={{marginLeft:"15px", width:"135px"}}>
                                <span>Data Inicial</span>
                                <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px", width:"135px"}}>
                                <span>Data Final</span>
                                <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{ marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                            <label>Status da Mensagem</label>
                            <select className="form-select" value={statusMessages} onChange={e => setStatusMessages(parseInt(e.target.value))}>
                                <option value={-99} selected>Todos</option>
                                {dataStatus.map((item) => {
                                    return(<option value={item.idstatusmessage}>{item.statusmessages}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{ marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                            <label>Tipo de Número</label>
                            <select className="form-select" value={numberType} onChange={e => setNumberType(parseInt(e.target.value))}>
                                <option value={-1} selected>Todos</option>
                                <option value={0} selected>Móvel</option>
                                <option value={1} selected>Fixo</option>
                                <option value={2} selected>Não Informado</option>
                            </select>
                        </div>
                        <div style={{ marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                            <label>Tipo de Alerta</label>
                            <select className="form-select" value={messageType} onChange={e => setMessageType(parseInt(e.target.value))}>
                                <option value={-1} selected>Todos</option>
                                {dataMessageType.map((item) => {
                                    return(<option value={item.value}>{item.label}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{marginLeft:"15px", display:"flex",alignItems:"center", marginTop:"10px"}}>
                            <button className="btn btn-success" title="Clique aqui para pesquisar" onClick={e => handleSearch(e)}><i className="fa fa-search"/></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/history-message' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Mensagens Whatsapp"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      />  
            </div>                    
        </ContentWrapper>
    )
}