import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { Button } from 'react-bootstrap';


import { SelectGroup, ValidationForm } from 'react-bootstrap4-form-validation';

export default function PermissionsUser(props) {
    const [loading, setLoading]                           = useState(false)
    const [loadingButton, setLoadingButton]               = useState(false)
    const [loadingButtonMatch, setLoadingButtonMatch]     = useState(false)
    const [loadingButtonNoMatch, setLoadingButtonNoMatch] = useState(false)

    const [iduserviroma, setIdUserViroma ]      = useState(null)
    const [iduserescriba, setIdUserEscriba ]    = useState(null)
    const [userescriba, setUserEscriba]         = useState('')

    const [bonds, setBonds]                     = useState([])

    const [userDataVIROMA, setUserDataVIROMA]   = useState([])
    const [userDataESCRIBA, setUserDataESCRIBA] = useState([])

    const getUsers = async(value ) => {
        try {
            setLoading(true)
            await api.get('/getUserIntegration')
                     .then(async(response) => {
                        setUserDataVIROMA(response.data.userVIROMA || [])
                        setUserDataESCRIBA(response.data.userEscriba || [])  

                        await api.get('/userintegration')
                                 .then(response => {
                                    setBonds(response.data)             
                                    setLoading(false)
                                })                 
                    })

        } catch (response) {
            setLoading(false)
            ShowMessages(response)
        }
    }

    const handleDelete  = async(id) => {
        try {
            await api.delete(`/userintegration/${id}`)
                     .then(async(response) => {
                        await api.get('/getUserIntegration')
                                 .then(async(responseInt) => {
                                    setUserDataVIROMA(responseInt.data.userVIROMA || [])
                                    setUserDataESCRIBA(responseInt.data.userEscriba || [])  
   
                                    await api.get('/userintegration')
                                             .then(responseData => {
                                                setBonds(responseData.data)             
                                                ShowMessages(response)
                                    })                 
                       })
                        
                    })

        } catch (response) {
            ShowMessages(response)
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        setLoadingButton(true)
        await api.post('/userintegration',{iduserviroma, iduserescriba,userescriba})
                 .then(async response => {
                    await api.get('/userintegration')
                             .then(responseData => {
                                setBonds(responseData.data)  
                                setLoadingButton(false)  
                                setUserDataVIROMA(userDataVIROMA => userDataVIROMA.filter((_item) => {return (parseInt(_item.iduser) !== parseInt(iduserviroma))})) 
                                setUserDataESCRIBA(userDataESCRIBA => userDataESCRIBA.filter((_item) => {return (parseInt(_item.codrecivil) !== parseInt(iduserescriba))})) 
                                ShowMessages(response)        
                            })  
                  })
                 .catch(error => {
                    console.log(error)
                    ShowMessages(error)
                    setLoadingButton(false)
                 })   

  
    }

    const handleMatch = async(e) => {
        setLoadingButtonMatch(true)
        await api.post('/integrationmatch')
                 .then(async response => {
                    await api.get('/getUserIntegration')
                             .then(async(responseInt) => {
                                setUserDataVIROMA(responseInt.data.userVIROMA || [])
                                setUserDataESCRIBA(responseInt.data.userEscriba || [])  

                             await api.get('/userintegration')
                                      .then(responseData => {
                                          setBonds(responseData.data)             
                                          ShowMessages(response)
                                          setLoadingButtonMatch(false)
                            })                 
                    })
                 })
                 .catch(error => {
                    console.log(error)
                    ShowMessages(error)
                    setLoadingButtonMatch(false)
                })
    }

    const handleNoMatch = async(e) => {
        setLoadingButtonNoMatch(true)
        await api.post('/integrationnomatch')
                 .then(async response => {
                    await api.get('/getUserIntegration')
                             .then(async(responseInt) => {
                                setUserDataVIROMA(responseInt.data.userVIROMA || [])
                                setUserDataESCRIBA(responseInt.data.userEscriba || [])  

                             await api.get('/userintegration')
                                      .then(responseData => {
                                          setBonds(responseData.data)             
                                          ShowMessages(response)
                                          setLoadingButtonNoMatch(false)
                            })                 
                    })
                 })
                 .catch(error => {
                    console.log(error)
                    ShowMessages(error)
                    setLoadingButtonNoMatch(false)
                })
    }

    useEffect(() => {
        getUsers()
        setStatistic('maintenance\\integration-escriba')
    },[])

    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            <Loading loading={loading} addClass="heigth60vh"/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div>
                    <div style={{padding: "10px", borderRadius: "5px", border: "1px solid #cacaca", width: "50%", marginTop:"15px"}}>
                        <div style={{marginTop: "-21px", backgroundColor: '#fff', width: "fit-content"}}>
                            <span>Integração entre usuários</span>
                        </div>
                        <div style={{marginTop:"15px"}}>
                            <div>
                                <button className="btn btn-success" title="Vincular usuários automaticamente" onClick={e => handleMatch()} disabled={loadingButtonMatch}>{loadingButtonMatch ?<i className="fa fa-spinner fa-spin"/> : <i className="fa fa-people-arrows-left-right"/>} </button>
                                <button className="btn btn-danger" title="Remover vinculo de todos usuários" style={{marginLeft:"5px"}} onClick={e => handleNoMatch()} disabled={loadingButtonNoMatch}>{loadingButtonNoMatch ?<i className="fa fa-spinner fa-spin"/> : <i className="fa fa-users-slash"/>}</button>
                            </div>
                            <ValidationForm  setFocusOnError={true}> 
                                <div style={{marginTop:"15px", display:"flex"}}>
                                    <div style={{width:"46%"}}>
                                        <label>Usuário VIROMA</label>
                                        <SelectGroup 
                                            name="usergroup" 
                                            id="usergroup" 
                                            placeholder="Selecione" 
                                            onChange={e => setIdUserViroma(e.target.value)}
                                            value={iduserviroma}
                                            className="form-select">
                                            <option value="">Selecione</option>
                                            {userDataVIROMA.map((_item) => {
                                                return (<option value={_item.iduser}>{_item.name}</option>)
                                            })}
                                        </SelectGroup> 
                                    </div>
                                    <div style={{width:"46%", marginLeft:"15px"}}>
                                        <label>Usuário Escriba</label>
                                        <SelectGroup 
                                            name="usergroup" 
                                            id="usergroup" 
                                            placeholder="Selecione" 
                                            value={iduserescriba}
                                            onChange={e => { setIdUserEscriba(e.target.value)
                                                             setUserEscriba(e.target.options[e.target.selectedIndex].text)}}
                                            className="form-select">
                                            <option value="">Selecione</option>
                                            {userDataESCRIBA.map((_item) => {
                                                return (<option value={_item.codrecivil}>{_item.nome}</option>)
                                            })}
                                        </SelectGroup> 
                                    </div>

                                    <div style={{width:"8%", marginLeft:"15px", display:"flex",alignItems:"center"}}>
                                        <button type="button" disabled={loadingButton} className="btn btn-success" title="Vincular usuário" style={{marginTop:"18px"}} onClick={e => handleSubmit(e)}>{loadingButton ?<i className="fa fa-spinner fa-spin"/> : <i className="fa fa-user-check"/>}</button>
                                    </div>
                                </div>
                            </ValidationForm>

                            <div style={{overflowX:"auto",height:"310px", marginTop:"15px"}} className={`${bonds.length === 0 ? "hidden" : ""}`}>
                                <table className="table table-striped w-100" >
                                    <thead className="thead-theme">
                                        <tr className="head-th">
                                            <td className="head-th" style={{width:"45%"}}>Usuário VIROMA</td>
                                            <td className="head-th" style={{width:"45%"}}>Usuário Escriba</td>
                                            <td style={{textAlign:"center", width:"100px"}}>
                                                        ...
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bonds.map((item, key) => {
                                            return (
                                                <tr className="head-th" key={key}>
                                                    <td>{item.userviroma}</td>
                                                    <td>{item.userescriba}</td>

                                                    <td style={{textAlign:"center"}}>
                                                        <button type="button" className="btn btn-danger btn-sm" onClick={e => handleDelete(item.iduserintegration)}><em className="fa fa-trash" ></em></button>
                                                    </td>
                                                </tr>
                                                    )
                                                })}     
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className={`box-sem-dados bg-warning ${bonds.length > 0 ? "hidden" : ""}`} style={{marginTop:"15px"}}>Ainda não foi incluido nenhum vinculo.</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}