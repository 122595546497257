import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import { Modal, Alert, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import TooltipItem from '../../components/tooltip';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';
import MaskWithValidation from './../../components/maskInput'
import {  cnpj } from 'cpf-cnpj-validator';
import Cards from 'react-credit-cards';

import CEP from 'cep-promise'
import api from '../../services/api';
import setStatistic from './../../services/apiStatistic'

import SEM_IMAGEM from './../../assets/sem-imagem.png'
import 'react-credit-cards/es/styles-compiled.css';
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCheckout({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]     = useState(false)
    const [tabActive, setTabActive] = useState(0)

    const [cvv,setCvv]              = useState('')
    const [month,setMonth]          = useState('')
    const [year,setYear]            = useState('')
    const [name, setName]           = useState('')
    const [number, setNumber]       = useState('')

    const [focus, setFocus]        = useState('cvc')

    const [placeholderName, setPlaceHolderName] = useState({ name : 'Seu nome'})
    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-checkout'),document.querySelector('#submit-checkout').children[0].classList,document.querySelector('#submit-checkout').lastChild.nodeValue)
        try {
            let _data = {  }

            if (editState)
                await api.put('/company/' + data.idcompany, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-credit-card','Efetuar Pagamento') 
                            } else 
                                loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-credit-card','Efetuar Pagamento') })
            else
                await api.post('/company', _data)
                         .then(response => {
                            ShowMessages(response) 
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-credit-card','Efetuar Pagamento')         
                             } else 
                                loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-credit-card','Efetuar Pagamento') 
            })
        } catch (response) {
            loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-credit-card','Efetuar Pagamento') 
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getCompanyData')
                     .then(response => {
                        if (response.ok) {

                        }
                     })
            
            setLoading(false)            
        } catch (response) {
            ShowMessages(response)    
        }
    }
    
    useEffect(() => {

    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-checkout')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height550 width500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-credit-card"></i> Checkout de Pagamento
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>             
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    Cartão de Crédito
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    Boleto Bancário
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 2 ? "active" : ""} onClick={() => { setTabActive(2)}}>
                                    PIX
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId={0}>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <div>
                                        <Cards
                                            cvc={cvv}
                                            expiry={`${month}/${year}`}
                                            name={name}
                                            number={number}
                                            focus={focus}
                                            placeholders={placeholderName}
                                        /> 
                                    </div>
                                    

                                    <div>
                                        <div style={{marginTop:"10px"}}>
                                            <TextInput  
                                                name="name"
                                                id="name"
                                                type="text"
                                                required
                                                autoFocus={true}
                                                maxLength={150}
                                                errorMessage={{ required: "Por favor, informe um nome!" }}
                                                value={name}
                                                placeholder="Nome Impresso no Cartão"
                                                className="form-control "
                                                onChange={e => setName(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                        <div style={{marginTop:"10px"}}>
                                            <TextInput  
                                                name="number"
                                                id="number"
                                                type="text"
                                                required
                                                maxLength={16}
                                                errorMessage={{ required: "Por favor, informe um número de cartão de crédito!" }}
                                                value={number}
                                                placeholder="Número do Cartão"
                                                className="form-control "
                                                onChange={e => setNumber(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                        <div style={{display:"flex", marginTop:"10px"}}>
                                            <div>
                                                <TextInput  
                                                    name="month"
                                                    id="month"
                                                    type="text"
                                                    required
                                                    maxLength={2}
                                                    errorMessage={{ required: "Por favor, informe um mês!" }}
                                                    value={month}
                                                    placeholder="Mês"
                                                    className="form-control "
                                                    onChange={e => setMonth(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                            <div style={{marginLeft:"15px"}}>
                                                <TextInput  
                                                    name="year"
                                                    id="year"
                                                    type="text"
                                                    required
                                                    maxLength={4}
                                                    errorMessage={{ required: "Por favor, informe um ano!" }}
                                                    value={year}
                                                    placeholder="Ano"
                                                    className="form-control "
                                                    onChange={e => setYear(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                            <div style={{marginLeft:"15px"}}>
                                                <TextInput  
                                                    name="cvv"
                                                    id="cvv"
                                                    type="text"
                                                    required
                                                    maxLength={4}
                                                    errorMessage={{ required: "Por favor, informe o código de segurança!" }}
                                                    value={cvv}
                                                    placeholder="CVV"
                                                    className="form-control "
                                                    onChange={e => setCvv(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                        </div>                                     
                                    </div>                             
                                </div>         
                            </TabPane>
                            <TabPane tabId={1}>
                                <div style={{display:"flex"}}>
                                   boleto
                                </div>                                                   
                            </TabPane>
                            <TabPane tabId={2}>
                                <div style={{display:"flex"}}>
                                    pix
                                </div>                  
                                
                            </TabPane>
                           
                       
                        </TabContent>     
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-checkout' className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-credit-card"></em> Efetuar Pagamento
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}