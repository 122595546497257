import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import MaskWithValidation from './../../components/maskInput'
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCSAT({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [description,setDescription]          = useState('')
    const [daysafter, setDaysAfter]             = useState(0)
    const [idstatusact, setIdStatusAct]         = useState(0)
    const [number, setNumber]                   = useState('')

    const [dataStatus, setDataStatus]           = useState([])
    const [dataNumber, setDataNumber]           = useState([])

    const [question, setQuestion]               = useState('')
    const [responseType, setResponseType]       = useState('')
    const [responseText, setResponseText]       = useState('')
    const [startInterval, setStartInterval]     = useState(0)
    const [endInterval, setEndInterval]         = useState(0)
    const [sendOnly, setSendOnly]               = useState('')
    const [id, setID]                           = useState(0)
    const [be, setBe]                           = useState(0)
    const [be2, setBe2]                         = useState(0)
    const [startFlux, setStartFlux]             = useState(false)
    const [endFlux, setEndFlux]                 = useState(false)
    const [email, setEmail]                     = useState('')
    const [whatsapp, setWhatsapp]               = useState('')
    const [buttons, setButtons]                 = useState({
        button1 : '',
        button2 : '',
        button3 : '',
        button4 : '',
        button5 : '',
    })
    
    const [csats, setCSATs]                     = useState([])

    const removeCSAT = (id) => {
        const _remove = csats.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            return false;
        });
        setCSATs(_remove)
    }

    const editCSAT = (id) => {
        let _data = csats.find(el => el.id === id)
        setQuestion(_data.question)
        setResponseType(_data.responseType)
        setResponseText(_data.responseText)
        setStartInterval(_data.startInterval)
        setEndInterval(_data.endInterval)
        setBe(_data.be)
        setBe2(_data.be2)
        setSendOnly(_data.sendOnly)
        setButtons(_data.buttons || [])
        setID(_data.id)
        removeCSAT(id)  
        setStartFlux(_data.start)
        setEndFlux(_data.end)
        setEmail(_data.email || '')
        setWhatsapp(_data.whatsapp || '')
    }

    const addCSAT = (e) => {
        e.preventDefault()

        if (responseType === 1) {
            if (startInterval === ''){
                ShowMessages({status : 500, message : 'Informe o intervalo inicial.'})
                return 
            }

            if (startInterval < 0){
                ShowMessages({status : 500, message : 'O intervalo inicial não pode ser menor que 0'})
                return 
            }

            if (endInterval === ''){
                ShowMessages({status : 500, message : 'Informe o intervalo final.'})
                return 
            }

            if (endInterval < 0){
                ShowMessages({status : 500, message : 'O intervalo final não pode ser menor que 0'})
                return 
            }

            if (endInterval <= startInterval){
                ShowMessages({status : 500, message : 'O intervalo final não pode ser menor ou igual ao intervalo inicial'})
                return 
            }
        }

        setCSATs([...csats,{
            id,
            question,
            responseType,
            responseText,
            startInterval,
            endInterval,
            buttons,
            sendOnly,
            be,
            be2,
            start : startFlux,
            end : endFlux,
            email,
            whatsapp
        }])

        setQuestion('')
        setID(0)
        setResponseText('')
        setResponseType('')
        setStartInterval(0)
        setEndInterval(0)
        setSendOnly('')
        setBe(0)
        setBe2(0)
        setStartFlux(false)
        setEndFlux(false)
        setButtons([])
        setEmail('')
        setWhatsapp('')
    }

    const setData = (obj,clear) => {
        setDescription(clear ? '' : obj.description) 
        setDaysAfter(clear ? '' : obj.daysafter) 
        setIdStatusAct(clear ? '' : obj.idstatusact) 
        setNumber(clear ? '' : obj.number)
        setCSATs(clear ? [] : obj.csats)

        setQuestion('')
        setResponseType('')
        setResponseText('')
        setStartInterval(0)
        setEndInterval(0)
        setBe(0)
        setBe2(0)
        setID(0)
        setStartFlux(false)
        setEndFlux(false)
        setSendOnly('')
        setButtons([])
    }

    const handleButton = async(value,index) => {
        setButtons({ ...buttons, ['button' + index]: value });
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-csat'),document.querySelector('#submit-csat').children[0].classList,document.querySelector('#submit-csat').lastChild.nodeValue)
        try {
            let _data = {description,
                         daysafter,
                         idstatusact,
                         number,
                         csats}

            if (editState)
                await api.put('/csat/' + data.idcsatsetting, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-csat'))
                         } else
                             loadingButton(false,document.querySelector('#submit-csat'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-csat'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/csat', _data)
                         .then(response => {
                         ShowMessages(response)
                         if (response.ok) {         
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-csat'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-csat'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-csat'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-csat'))
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        await api.get('/getmessages-whatsData')
                 .then(response => {
                    setDataStatus(response.data.statusact)
                    setDataNumber(response.data.numbers)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-csat')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Propriedades do CSAT
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>    
                        <div style={{display:"flex"}}>
                            <div style={{width:"320px"}}>
                                <label>Descrição</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="description"
                                        id="description"
                                        type="text"
                                        required
                                        autoFocus={true}
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                        value={description}
                                        className="form-control "
                                        onChange={e => setDescription(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"230px"}}>
                                <label>Enviar após</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="daysafter" 
                                        id="daysafter" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe quanto tempo devemos enviar!" }}
                                        className="form-select"
                                        value={daysafter} 
                                        onChange={e => setDaysAfter(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            <option value="0">Mesmo Dia | 1hr Depois</option>
                                            <option value="1">1 Dia</option>
                                            <option value="2">2 Dias</option>
                                            <option value="3">3 Dias</option>
                                            <option value="4">4 Dias</option>
                                            <option value="5">5 Dias</option>
                                            <option value="6">6 Dias</option>
                                            <option value="7">7 Dias</option>
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"230px"}}>
                                <label>A partir do status</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="idstatusact" 
                                        id="idstatusact" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe a partir de qual status!" }}
                                        className="form-select"
                                        value={idstatusact} 
                                        onChange={e => setIdStatusAct(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {dataStatus.map(_status => {
                                                return(<option value={_status.value}>{_status.label}</option>)
                                            })}
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"230px"}}>
                                <label>Pelo número</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="number" 
                                        id="number" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe o número a ser enviado!" }}
                                        className="form-select"
                                        value={number} 
                                        onChange={e => setNumber(e.target.value)}>
                                            <option value="">Selecione</option>
                                        {dataNumber.map(_number => {
                                            return(<option value={_number.number}>{_number.phone_format}</option>)
                                        })}
                                    </SelectGroup> 
                                </div>
                            </div> 
                        </div>  
                        <div style={{display:"flex", marginTop:"15px", borderTop:"1px dashed #cacaca", paddingTop:"10px"}}>
                            <div style={{width:"430px"}}>
                                <label>Pergunta</label>
                                <textarea className="form-control" value={question} onChange={e => setQuestion(e.target.value)} rows={10}></textarea>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <div style={{width:"330px", display:"flex"}}>
                                    <div style={{width:"70px"}}>
                                        <label>ID</label>
                                        <input className="form-control" value={id} onChange={e => setID(e.target.value)}/>
                                    </div>
                                    <div style={{marginLeft:"15px", width:"100%"}}>
                                        <label>Tipo da Resposta</label>
                                        <select className="form-select" value={responseType} onChange={e => {setResponseType(parseInt(e.target.value), 
                                                                                                            setResponseText(e.target.options[e.target.selectedIndex].text))}}>
                                            <option value="">Selecione</option>
                                            <option value="0">Texto Livre</option>
                                            <option value="1">Intervalo Númerico</option>
                                            <option value="2">Botões</option>
                                        </select>
                                    </div>
                                </div>

                                {csats.length > 0 ?
                                    <div style={{width:"330px"}}>
                                        <label>Enviar somente se a resposta da pergunta</label>
                                        <select className="form-select" value={sendOnly} onChange={e => setSendOnly(e.target.value)}>
                                            <option value="">Selecione</option>
                                            {csats.map((item, key) => {
                                                return (<option value={item.id}>{item.question.substring(0,50)}...</option>)
                                            })}
                                        </select>
                                        <div style={{display:"flex"}}>
                                            <div>
                                                <label>for</label>
                                                <input type="number" className="form-control" value={be} onChange={e => setBe(e.target.value)}/>
                                            </div>
                                            <div style={{marginLeft:"15px"}}>
                                                <label>ou</label>
                                                <input type="number" className="form-control" value={be2} onChange={e => setBe2(e.target.value)}/>
                                            </div>
                                            <div style={{marginLeft:"15px", width:"150px", display:"flex", flexDirection:"column"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={startFlux} value={startFlux} onClick={e => setStartFlux(!startFlux)}  />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{ marginTop: "5px", textAlign:"center" }}>Inicio do Fluxo</span>
                                            </div>
                                            <div style={{marginLeft:"15px", width:"150px", display:"flex", flexDirection:"column"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={endFlux} value={endFlux} onClick={e => setEndFlux(!endFlux)}  />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{ marginTop: "5px", textAlign:"center" }}>Fim do Fluxo</span>
                                            </div>
                                            {responseType === 0 ?
                                                <div style={{marginLeft:"15px", marginTop:"17px"}}>
                                                    <button type="button" className="btn btn-success" onClick={e => addCSAT(e)}><i className="fa fa-plus"/></button>
                                                </div>
                                            : ''}
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div>
                                                <label>Notificar por Email</label>
                                                <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
                                            </div>
                                            <div style={{marginLeft:"5px"}}>
                                                <label>Notificar por WhatsApp</label>
                                                <MaskWithValidation
                                                    name="whatsapp"
                                                    id="whatsapp"
                                                    maxLength={25}
                                                    value={whatsapp}
                                                    errorMessage={{ required: "Por favor, informe um número!" }}
                                                    className="form-control"
                                                    onChange={e =>  setWhatsapp(e.target.value)}
                                                    autoComplete="off"
                                                    mask={[/[0-9]/,/[0-9]/,' ','(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}/>
                                            </div>
                                        </div>
                                    </div>
                                : ""}
                            </div>            
                        </div>
                        <div style={{display:"flex",alignItems:'flex-end'}}>
                            {responseType === 1 ?                  
                                <div style={{marginTop:"15px", display:"flex"}}>
                                    <div>
                                        <label>Intervalo Inicial</label>
                                        <input type="number" value={startInterval} onChange={e => setStartInterval(e.target.value)} className="form-control"/>
                                    </div>
                                    <div style={{marginLeft:"15px"}}>
                                        <label>Intervalo Final</label>
                                        <input type="number" value={endInterval} onChange={e => setEndInterval(e.target.value)} className="form-control"/>
                                    </div>
                                </div>
                            : ""}
                            {responseType === 2 ?    
                                <div style={{marginTop:"15px",display:"flex"}}>
                                    <div>
                                        <label>Texto do Botão 01</label>
                                        <input type="text" value={buttons.button1} onChange={e => handleButton(e.target.value,1)} className="form-control"/>
                                    </div>
                                    <div style={{marginLeft : "15px"}}>
                                        <label>Texto do Botão 02</label>
                                        <input type="text" value={buttons.button2} onChange={e => handleButton(e.target.value,2)} className="form-control"/>
                                    </div>
                                    <div style={{marginLeft : "15px"}}>
                                        <label>Texto do Botão 03</label>
                                        <input type="text" value={buttons.button3} onChange={e => handleButton(e.target.value,3)} className="form-control"/>
                                    </div>
                                    <div style={{marginLeft : "15px"}}> 
                                        <label>Texto do Botão 04</label>
                                        <input type="text" value={buttons.button4} onChange={e => handleButton(e.target.value,4)} className="form-control"/>
                                    </div>
                                    <div style={{marginLeft : "15px"}}>
                                        <label>Texto do Botão 05</label>
                                        <input type="text" value={buttons.button5} onChange={e => handleButton(e.target.value,5)} className="form-control"/>
                                    </div>
                                </div>
                            : ""} 
                            {responseType > 0 ? 
                                <div style={{marginLeft:"15px"}}>
                                    <button type="button" className="btn btn-success" onClick={e => addCSAT(e)}><i className="fa fa-plus"/></button>       
                                </div>
                            : ""}
                        </div>
                        <div style={{marginTop:"15px", height:"250px", overflowY:"auto"}}>
                            <table className="table table-striped w-100">
                                <thead className="thead-theme">
                                    <tr className="head-th">
                                        <td style={{width:"75px", minWidth:"75px", maxWidth:"75px"}} className="head-th">ID</td>
                                        <td style={{width:"175px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Pergunta</td>
                                        <td style={{width:"125px", minWidth:"125px", maxWidth:"125px"}} className="head-th">Tipo</td>
                                        <td className="head-th">Outras Informações</td>        
                                        <td style={{textAlign:"center", width:"100px"}}>
                                           ...
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {csats.map((item, key) => {
                                        return (
                                            <tr className="head-th" key={key} onDoubleClick ={e => editCSAT(item.id)}>
                                                <td>{item.id}</td>
                                                <td>{item.question}</td>
                                                <td>{item.responseText}</td>
                                                <td>
                                                    {item.responseType === 1 ?
                                                        `de ${item.startInterval} até ${item.endInterval}`
                                                    : ''}
                                                    {item.responseType === 2 ?
                                                        Object.values(item.buttons).map(_item => {
                                                            return (_item + ' | ')
                                                        })
                                                    : ''}
                                                </td>
        
                                                        <td style={{textAlign:"center"}}>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={e => removeCSAT(item.id)}><em className="fa fa-trash"></em></button>
                                                            <button type="button" className="btn btn-warning  btn-sm" style={{marginLeft:"5px"}} onClick={e => editCSAT(item.id)}><em className="fa fa-edit"></em></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}      
                                        </tbody>
                                    </table>
                                    <div className={`box-sem-dados bg-warning ${csats.length > 0 ? "hidden" : ""}`}>Ainda não foi incluido nenhuma configuração.</div>
                        </div>                                 
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-csat" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}