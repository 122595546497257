import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button,} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';
import {format} from 'date-fns'

//import { Async } from "react-select-virtualized";
import setStatistic from '../../services/apiStatistic'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCompanyDominio({showModal, handleCloseModal, data}) {
    const [loading, setLoading]                                    = useState(false)

    const [enquadramento, setenquadramento]                        = useState('')
    const [apel_emp, setapel_emp]                                  = useState('')
    const [descricao, setdescricao]                                = useState('')    
    const [cgce_emp, setcgce_emp]                                  = useState('')
    const [codi_emp, setcodi_emp]                                  = useState('')
    const [esta_emp, setesta_emp]                                  = useState('')
    const [i_cnae20, seti_cnae20]                                  = useState('')
    const [nome_emp, setnome_emp]                                  = useState('')
    const [obs_fiscal, setobs_fiscal]                              = useState('')
    const [obs_pessoal, setobs_pessoal]                            = useState('')
    const [validade_fim, setvalidade_fim]                          = useState('')
    const [statuscertificate, setstatuscertificate]                = useState('')

    const setData = () => {
        setapel_emp(data.apel_emp)
        setcgce_emp(data.cgce_emp)
        setcodi_emp(data.codi_emp)
        setesta_emp(data.esta_emp)
        seti_cnae20(data.i_cnae20, )
        setnome_emp(data.nome_emp)
        setobs_fiscal(data.obs_fiscal)
        setobs_pessoal(data.obs_pessoal)
        setvalidade_fim(data.validade_fim ? format(new Date(data.validade_fim),'dd/MM/yyyy') : '')
        setenquadramento(data.enquadramento)
        setdescricao(data.descricao)
        setstatuscertificate(data.diasvencimentocertificado)
    }

    useEffect(() => {
        setData()
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-company')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height700" dialogAs={DraggableModalDialog}>
            <ValidationForm  className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-building"></i> Propriedades da Empresa
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth390px"/>
                    <div className={`${loading ? "hidden" : ""}`}> 
                        <div style={{display:"flex", gap:"5px"}}>
                            <div>
                                <label>Código da Empresa</label>
                                <input readOnly value={codi_emp} onChange={e => setcodi_emp(e.target.value)} className='form-control'/>
                            </div>
                            <div>
                                <label>CNPJ</label>
                                <input readOnly value={cgce_emp} onChange={e => setcgce_emp(e.target.value)} className='form-control'/>
                            </div>
                            <div>
                                <label>Validade Certificado</label>
                                <input readOnly value={validade_fim} onChange={e =>setvalidade_fim(e.target.value) } className='form-control'/>
                            </div>  
                            <div>
                                <label>Status do Certificado</label>
                                <input readOnly value={statuscertificate} onChange={e => setstatuscertificate(e.target.value)} className='form-control'/>
                            </div>
                            <div style={{width:"75px"}}>
                                <label>UF</label>
                                <input readOnly value={esta_emp} onChange={e => setesta_emp(e.target.value)} className='form-control'/>
                            </div>
                        </div>

                        <div>
                            <div>
                                <label>Apelido</label>
                                <input readOnly value={apel_emp} onChange={e => setapel_emp(e.target.value)} className='form-control'/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>Nome</label>
                                <input readOnly value={nome_emp} onChange={e => setnome_emp(e.target.value)} className='form-control'/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>Enquadramento</label>
                                <input readOnly value={enquadramento} onChange={e => setenquadramento(e.target.value)} className='form-control'/>
                            </div>
                        </div>
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"150px"}}>
                                <label>Codigo do CNAE 2.0</label>
                                <input readOnly value={i_cnae20} onChange={e =>seti_cnae20(e.target.value) } className='form-control'/>
                            </div>
                            <div style={{width:"100%"}}>
                                <label>Descrição CNAE</label>
                                <input readOnly value={descricao} onChange={e =>setdescricao(e.target.value) } className='form-control'/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>Obs. Fiscal</label>
                                <input readOnly value={obs_fiscal} onChange={e => setobs_fiscal(e.target.value)} className='form-control'/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>Obs. Pessoal</label>
                                <input readOnly value={obs_pessoal} onChange={e => setobs_pessoal(e.target.value)} className='form-control'/>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}