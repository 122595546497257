import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from './../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from './../../services/apiStatistic'
import  loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalOccupation({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [occupation,setOccupation] = useState('')

    const setData = (obj,clear) => {
        setOccupation(clear ? '' : obj.occupation) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-occupation'),document.querySelector('#submit-occupation').children[0].classList,document.querySelector('#submit-occupation').lastChild.nodeValue)
        try {
            let _data = {occupation}

            if (editState)
                await api.put('/occupation/' + data.idoccupation, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-occupation'))
                         } else
                             loadingButton(false,document.querySelector('#submit-occupation'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-occupation'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/occupation', _data)
                         .then(response => {
                         ShowMessages(response)
                         if (response.ok) {         
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-occupation'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-occupation'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-occupation'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-occupation'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-occupation')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Propriedades do Cargo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Cargo</label>
                            <div className="input-group">
                                <TextInput  
                                    name="occupation"
                                    id="occupation"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um cargo!" }}
                                    value={occupation}
                                    className="form-control "
                                    onChange={e => setOccupation(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>                                    
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-occupation" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}