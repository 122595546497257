import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import MaskWithValidation from './../../components/maskInput';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMyNumber({showModal, handleCloseModal, data, handleReload}) {
    const [loadingBtn, setLoadingBtn]       = useState(false)
    const [number,setNumber]                = useState('')
    const [numberFormat, setNumberFormat]   = useState('')
    const [sender, setSender]               = useState('')
    const [environment, setEnvironment]     = useState('')

    const StatusWhatsapp = async() => {
        await api.get('/getAllMyNumbers')
                 .then(async(response) => { 
                    for (const [idx, _row] of response.data.entries()) {
                        await api.post('/api/v1/getStatusNumber',{number : _row.number.replace(/[\W]+/g,""),
                                                                  hash   : _row.environment })
                                 .then(async(_status) => {  
                                    
                                 }).catch(error => {
                                    ShowMessages(error)
                                 })
                    }

                 }).catch(error => {
                    ShowMessages(error)
                 })  
        
    }

    const setData = (obj,clear) => {
        setNumber(clear ? '' : obj.number) 
        setNumberFormat(clear ? '' : obj.phone_format)
        setEnvironment(clear ? '' : obj.environment)
        setSender('')
    }

    const sendMessage = async() => {
        if (sender === '') {
            ShowMessages({message : "Informe o número do destinatário de mensagem.", status : 500})
            return
        }
        setLoadingBtn(true)
        await api.post('/api/v1/sendMessage',{number      : number.replace(/[\W]+/g,""),
                                              sender      : sender.replace(/[\W]+/g,""),
                                              hash        : environment,
                                              bodyMessage : "Mensagem de teste da VIROMA Tecnologia"})
                 .then((response) => {
                    StatusWhatsapp()
                    if (response.data.data.sent)
                        ShowMessages({message : "Mensagem enviada com sucesso!",status : 200})
                    else {
                        ShowMessages({message : response.data.data.message,status : 500})
                    }
                    setLoadingBtn(false)
                 })
                 .catch((error) => {
                    setLoadingBtn(false)
                    ShowMessages(error)
                 })
    }

       
    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-mynumber')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm  setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-whatsapp"></i> Teste de Conexão
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div>
                        <div className="bg-success" style={{padding:"10px", borderRadius:"5px", display:"flex"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <i className="fab fa-whatsapp" style={{fontSize:"55px"}}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <p>Parabéns, você está conectado com o número <span style={{fontWeight:'bold'}}>{numberFormat}</span></p>
                                <p>Caso queria enviar uma mensagem de teste, preencha o campo abaixo e clique em enviar, ou, se preferir alterar alguma informação de seu número, desconecte primeiro, para posteriormente fazer a alteração.</p>
                            </div>
                        </div>
                        <div style={{display:"flex", marginTop:"15px"}}>
                            <div>
                                <label>Informe o número de destino</label>
                                <MaskWithValidation
                                    name="sender"
                                    id="sender"
                                    maxLength={25}
                                    value={sender}
                                    required
                                    errorMessage={{ required: "Por favor, informe um número!" }}
                                    className="form-control"
                                    onChange={e =>  setSender(e.target.value)}
                                    autoComplete="off"
                                    mask={[/[0-9]/,/[0-9]/,' ','(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}/>
                            </div>
                            <div style={{marginLeft:"15px", display:"flex", alignItems:"center"}}>
                                <button type="button" className="btn btn-success" style={{marginTop:"17px"}} onClick={e => sendMessage()}>{loadingBtn ? <i className="fa fa-spin fa-spinner"/> : <i className="fa fa-paper-plane"/>}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}