import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import { Button } from 'reactstrap';

import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import ModalPDFViewer from "../modals/modal-pdf-viewer"

import { format } from 'date-fns'


export default function Commission(props) {
    const [loading, setLoading]                 = useState(false)

    const [year, setYear]                       = useState(format(new Date(),'yyyy'))
    const [companyData, setCompanyData]         = useState([])
    const [company, setCompany]                 = useState('')
    const [print, setPrint]                     = useState(false)
    const [url, setURL]                         = useState('')

    const getData = async() => {
        await api.get('/getcompanyDominio')
                 .then(response => {
                    setCompanyData(response.data || [])
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const handleSubmit = async() => {
        setURL('/showReportInvoices/' + year + '/' + company)
        setPrint(true)
  
    }

    useEffect(() => {
        getData()
    },[])

    return (    
        <ContentWrapper>
            <ModalPDFViewer showModal={print} handleCloseModal={setPrint} url={url} />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", flexDirection: 'column' }} >
                <div className='box-shadow' style={{padding:"15px", width:"550px"}}>
                    <div style={{ border: "1px solid #cacaca", borderRadius: "5px", padding: "15px" }}>
                        <div style={{ width: "100%", marginTop: "-30px" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold", background: "#fff" }}>Configurações de Relatório</span>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <label>Ano</label>
                                <select className='form-select' value={year} onChange={e => setYear(e.target.value)}>
                                    <option value="">Selecione</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                            <div style={{ width: "100%" }}>
                                <label>Empresa</label>
                                <select className="form-select" value={company} onChange={e => setCompany(e.target.value)}>
                                    <option value="0">Todos</option>
                                    {companyData.map((item, key) => {
                                        return <option key={key} value={item.value}>{item.value.toString().padStart(6,'0')} - {item.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button type="submit" className={`btn-theme ${loading ? "hidden" : ""}`} onClick={e => handleSubmit()}>
                    <em className="fa fa-print"></em> Gerar Relatório
                </Button>
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`, e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}