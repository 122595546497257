import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import CurrencyInput from './../../components/moneyInput';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCommission({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                   = useState(false)

    const [commission,setCommission]              = useState('')
    const [value,setValue]                        = useState(0)
    const [emolumentos,setEmolumentos]            = useState(0)
    const [cod, setCod]                           = useState('')

    const [user, setUser]                         = useState('')
    const [iduser, setIDUser]                     = useState(0)
    const [idProductionType, setIDProductionType] = useState('')
    const [productionType, setProductionType]     = useState('')
    const [percentual, setPercentual]             = useState(0)
    
    const [commissionData, setCommissionData]     = useState([])

    const [userData, setUserData]                 = useState([])

    const setData = (obj,clear) => {
        setCommission(clear ? '' : obj.commission) 
        setCommissionData(clear ? [] : obj.commissiondata || [])
        setCod(clear ? '' : obj.cod) 
        setValue(clear ? 0 : obj.value.toLocaleString('pt-br', {minimumFractionDigits: 2}) )
        setEmolumentos(clear ? 0 : obj.emolumentos.toLocaleString('pt-br', {minimumFractionDigits: 2}) )
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-commission'),document.querySelector('#submit-commission').children[0].classList,document.querySelector('#submit-commission').lastChild.nodeValue)
        try {
            let _data = {commission,
                         value,
                         cod,
                         emolumentos,
                         commissionData}

            if (editState)
                await api.put('/commission/' + data.idcommission, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-commission'))
                         } else
                             loadingButton(false,document.querySelector('#submit-commission'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-commission'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/commission', _data)
                         .then(response => {
                         ShowMessages(response)
                         if (response.ok) {         
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-commission'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-commission'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-commission'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-commission'))
            ShowMessages(response)
        }
    }
    
    const handleDelete = async(id) => {
        setCommissionData(commissionData => commissionData.filter((_item,key) => {return (key !== id)}))
    }

    const handleAdd = async () => {
        setCommissionData(commissionData => commissionData.concat({iduser,user,percentual,idProductionType, productionType}))
        setPercentual(0)
        setUser('')
        setIDUser(0)
        setIDProductionType('')
        setProductionType('')
    }

    const getData = async () => {   
        await api.get('/getUsers')
                 .then(async(response) => {
                     setUserData(response.data || [])
                 })
                 .catch(error => {

                 })
    }

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])


    useEffect(() => {
        getData()
        setStatistic('modals\\modal-commission')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-sack-dollar"></i> Propriedades de Comissões
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}> 
                        <div style={{display:"flex"}}>
                            <div style={{width:"125px"}}>
                                <label>Código</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="cod"
                                        id="cod"
                                        type="text"
                                        required
                                        autoFocus={true}
                                        maxLength={4}
                                        errorMessage={{ required: "Por favor, informe um código!" }}
                                        value={cod}
                                        className="form-control "
                                        onChange={e => setCod(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>  
                            <div  style={{marginLeft:"15px",width:"400px"}}>
                                <label>Descrição</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="commission"
                                        id="commission"
                                        type="text"
                                        required
                                        maxLength={200}
                                        errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                        value={commission}
                                        className="form-control "
                                        onChange={e => setCommission(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div> 
                            <div style={{width:"150px", marginLeft:'15px'}}>
                                <label>Comissão</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="value"
                                        id="value"
                                        type="text"
                                        required
                                        errorMessage={{ required: "Por favor, informe uma comissão!" }}
                                        value={value}
                                        maskoptions={{ decimalLimit : '3'}}
                                        className="form-control text-right"
                                        onChange={setValue}
                                        autoComplete="off"/>
                                </div>
                            </div> 

                            <div style={{width:"150px", marginLeft:'15px'}}>
                                <label>Emolumentos</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="emolumentos"
                                        id="emolumentos"
                                        type="text"
                                        required
                                        autoFocus={true}
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor de emolumento!" }}
                                        value={emolumentos}
                                        className="form-control text-right"
                                        onChange={setEmolumentos}
                                        autoComplete="off"/>
                                </div>
                            </div>   
                        </div>
    
                        <div>
                            <div style={{display:"flex",borderRadius: '5px', border: "1px dashed #cacaca", marginTop: '15px', flexDirection:"column"}}>
                                <div style={{marginTop: "-11px", marginLeft:"5px",backgroundColor: '#fff', width: "fit-content"}}>
                                    <span>Configurações de Comissões do Usuário</span>
                                </div>
                                <div style={{display:"flex", padding: "10px"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={{width:"350px"}}>
                                            <label>Usuário</label>
                                            <div className="input-group">
                                                <SelectGroup 
                                                    name="commissionType" 
                                                    id="commissionType" 
                                                    value={iduser}
                                                    onChange={e => {setIDUser(e.target.value)
                                                                    setUser(e.target.options[e.target.selectedIndex].text)}}
                                                    errorMessage={{ required: "Por favor, informe usuário!" }}
                                                    className="form-select">
                                                        
                                                    <option value="">Selecione</option>
                                                    {userData.map((item) => {
                                                        return (<option value={item.iduserescriba}>{item.name}</option>)
                                                    })}
                                                
                                                </SelectGroup>
                                            </div>
                                        </div> 
                                        <div style={{width:"185px", marginLeft:"15px"}}>
                                            <label>Tipo de Comissão</label>
                                            <div className="input-group">
                                                <SelectGroup 
                                                    name="commissionType" 
                                                    id="commissionType" 
                                                    value={idProductionType}
                                                    onChange={e => {setIDProductionType(e.target.value)
                                                                    setProductionType(e.target.options[e.target.selectedIndex].text)}}
                                                    errorMessage={{ required: "Por favor, informe um tipo de comissão!" }}
                                                    className="form-select">
                                                        
                                                    <option value="">Selecione</option>
                                                    <option value="0">Produção Individual</option>
                                                    <option value="1">Produção Total</option>
                                                </SelectGroup>
                                            </div>
                                        </div>
                                        <div style={{width:"130px", marginLeft:'15px'}}>
                                            <label>(%) Comissão</label>
                                            <div className="input-group">
                                                <CurrencyInput  
                                                    name="percentual"
                                                    id="percentual"
                                                    type="text"
                                                    errorMessage={{ required: "Por favor, informe uma comissão!" }}
                                                    value={percentual}
                                                    maskoptions={{ decimalLimit : '3'}}
                                                    className="form-control text-right"
                                                    onChange={setPercentual}
                                                    autoComplete="off"/>
                                            </div>
                                        </div> 
                                    </div>
                                    <div style={{marginLeft:"15px",  display:"flex", alignItems:"center"}}>
                                        <button type="button" className="btn btn-success" style={{marginTop:"17px"}} onClick={e => handleAdd()}><i className="fa fa-plus"/></button>
                                    </div>  
                                </div>

                                <div style={{overflowX:"auto",height:"250px",padding:"10px"}} className={`${commissionData.length === 0 ? "hidden" : ""}`}>
                                    <table className="table table-striped w-100" >
                                        <thead className="thead-theme">
                                            <tr className="head-th">
                                                <td className="head-th" style={{width:"35%"}}>Usuário</td>
                                                <td className="head-th" style={{width:"25%"}}>Tipo</td>
                                                <td className="head-th" style={{width:"20%", textAlign:"right"}}>% de Comissão</td>
                                                <td style={{textAlign:"center", width:"50px"}}>
                                                    ...
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           {commissionData.map((item, key) => {
                                                return (
                                                    <tr className="head-th" key={key}>
                                                        <td>{item.user}</td>
                                                        <td>{item.productionType}</td>
                                                        <td style={{textAlign:"right"}}>{item.percentual}</td>
                                                        <td style={{textAlign:"center"}}>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={e => handleDelete(key)}><em className="fa fa-trash" ></em></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}         
                                        </tbody>    
                                    </table>
                                </div>
                                <div className={`box-sem-dados bg-warning ${commissionData.length > 0 ? "hidden" : ""}`} style={{margin:"10px"}}>Ainda não foi incluido nenhuma configuração.</div>
                            </div>                                                                       
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-commission" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}
