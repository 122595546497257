import React, {useState, useEffect} from 'react'
import { Card, CardBody, Collapse } from 'reactstrap';

export default function Filter({isOpenFilter, filters, callback, callBackOpenFilter, basicFilters,externalFilters,reset, setReset}) {
    const [field, setField] = useState([])
    const [keys, setKeys]   = useState([])
    const [types, setTypes] = useState([])

    const [selectField, setSelectField]      = useState([])
    const [selectKey, setSelectKey]          = useState('%%')
    const [value, setValue]                  = useState('')
    const [value2, setValue2]                = useState('')
    const [ignoretime, setIgnoreTime]        = useState(false)
    const [collect, setCollect]              = useState(false)
    const [selectFilters, setSelectFilters ] = useState([])
    const [inputType, setInputType]          = useState('text')
    const [searchFields, setSearchFields]    = useState([])

    const [searchType, setSearchType]        = useState(0)
    const [operator, setOperator]            = useState(false)

    const _arSearch = [{"text"  : "Começa com...",
                        "value" : "%..",
                        "types" : ['string']},

                       {"text"  : "Contenha...",
                        "value" : "%%",
                        "types" : ['string']},

                        {"text"  : "Não contenha...",
                         "value" : "!%%",
                         "types" : ['string']},
    
                       {"text"  : "Maior que...",
                        "value" : ">",
                        "types" : ['string','number','date']},

                       {"text"  : "Maior e igual a...",
                        "value" : ">=",
                        "types" : ['string','number','date']},

                       {"text"  : "Menor que...",
                        "value" : "<",
                        "types" : ['string','number','date']},

                       {"text"  : "Menor e igual a...",
                        "value" : "<=",
                        "types" : ['string','number','date']},

                       {"text"  : "Igual a...",
                        "value" : "=",
                        "types" : ['string','number','date','boolean']},

                       {"text"  : "Diferente de...",
                        "value" : "<>",
                        "types" : ['string','number','date','boolean']},

                       {"text"  : "Termina com..",
                        "value" : "..%",
                        "types" : ['string']},
                                                
                       {"text"  : "Entre...",
                        "value" : "between",
                        "types" : ['number','date']}]

    // 0 : geral
    // 1 : between
    // 2 : boolean

    const handleSearch = (type) => {
        console.log(type)
        setSearchFields([])
        _arSearch.map((field) => {
            if (field.types.includes(type))
                setSearchFields(searchFields => searchFields.concat(field))
        })
    }


    useEffect(() => {
        setField([])
        Object.values(filters).map((filter, key) => {
            if ((filter.filter === undefined) || (filter.filter)) {
                setTypes(types => types.concat(filter.dataType === undefined ? '' : filter.dataType))
                setField(field => field.concat(filter))
                setKeys(keys   => keys.concat(Object.keys(filters)[key]))
                
                if (filter.searchDefault)
                    if (filter.searchDefault === true) {
                        handleSelectField(Object.keys(filters)[key])
                        handleSearch(Object.values(filters)[key].dataType === undefined ? 'string' : Object.values(filters)[key].dataType)
                    } else 
                        handleSelectField(Object.keys(filters)[0])

                if (filter.ignoretime)
                    if (filter.ignoretime === true)
                        setIgnoreTime(true)
            }
        })
    },[filters])

    useEffect(() => {
        if (reset) {
            setSelectFilters([])
            setReset(false)
        }
    },[reset])

    useEffect(() => {
        if (searchFields.length > 0)
            handleSelectKey(searchFields[0].value)
    },[searchFields])

    const handleSelectKey = (_key) => {
        console.log(_key)
        setSelectKey(_key)
        setSearchType(_key === 'between' ? 1 : searchType)
    }

    const handleSelectField = (_value) => {
        setSelectField(_value)
        setSearchType(0)
        setValue('')

        let _index = keys.indexOf(_value)

        if (types[_index] !== undefined) {
            if (types[_index] === 'date') {
                setInputType('date')
                handleSearch('date')
                handleSelectKey('=')
            } else if (types[_index] === 'number') {
                setInputType('number')
                handleSearch('number')
            } else if (types[_index] === 'boolean') {
                setInputType('text')
                setValue('true')
                handleSearch('boolean')
                handleSelectKey('=')
                setSearchType(2)
            } else {
                setInputType('text')
                handleSelectKey(_index > 0 ? _arSearch[_index].value : '%%')
                handleSearch('string')
            }
        } else {
            setInputType('text')
            setSelectKey('%%')
            handleSearch('string')
        }
    }

    const handleFilter = () => {
        let _filters    = { field  : selectField,
                            key    : selectKey,  
                            value  : value,
                            value2 : value2,
                            ignoretime,
                            operator : operator ? ' OR ' : ' AND ',   
                          }

        let _auxFilters = []                

        if ((externalFilters !== null) && (externalFilters !== undefined)) {
            externalFilters = externalFilters.filter(_filter => {
                return _filter.external === true
            })
            _auxFilters = _auxFilters.concat(externalFilters)               
        } 

        if ((basicFilters !== null) && (basicFilters !== undefined))
            if (Object.keys(basicFilters).length > 0)
                _auxFilters = _auxFilters.concat(basicFilters)               

                
        if (collect) 
            _auxFilters = _auxFilters.concat(selectFilters)
    

        if (!selectFilters.includes(_filters)) {
            _auxFilters.push(_filters) 
            setSelectFilters(selectFilters => selectFilters.concat(_filters))  
        } 

        callback(_auxFilters)              
    }

    const handleRemoveFilter = () => {
        if ((externalFilters !== null) && (externalFilters !== undefined))  {  
            console.log(externalFilters) 
            setSelectFilters(externalFilters)
            callback(externalFilters) 
        } else {
            setSelectFilters([])
            callback([]) 
        }
        
    }

    const deleteFilter = (_name, _value) => {
        let _filters =  [...new Set(externalFilters !== undefined ? selectFilters.concat(externalFilters) : selectFilters)].filter(_filter => {
            return  _filter.value !== _value
        })
        console.log(_filters)
        callback(_filters)
        setSelectFilters(_filters)
    }


    return (
        <Collapse  style={{width:"336px",marginRight:"10px", marginTop:"20px"}} isOpen={isOpenFilter}>
            <Card>
                <CardBody>
                    <a href="/#" className="button-close" onClick={e => callBackOpenFilter()} title="Fechar painel de filtros"><em className="fa fa-times"></em></a>
                    <div>
                        <div>
                            <label>Qual campo?</label>
                            <select className="form-select" placeholder="Selecione" value={selectField} onChange={e => handleSelectField(e.target.value)}>
                                {field.map((field, key) => {
                                    return (<option key={key} value={keys[key]}>{field.text}</option>)
                                })}
                            </select>
                        </div>
                        <div>
                            <label>Como pesquisar?</label>
                            <select className="form-select" defaultValue={selectKey} onChange={e => handleSelectKey(e.target.value)}>
                                {searchFields.map((field, key) => {
                                    return (<option key={key} value={field.value}>{field.text}</option>)
                                })}
                            </select>
                        </div>
                        <div>
                            <label>O que pesquisar?</label>
                            {searchType === 0 ? <div>
                                                    <input type={inputType} className="form-control" value={value}  onChange={e => setValue(e.target.value)}/> 
                                                </div>
                                              : searchType ===  1 ? <div style={{display:"flex",flexWrap:"wrap",flex: "1 1 auto", height: "80px", justifyContent:'center', marginBottom:"10px"}}>
                                                                        <input type={inputType} className="form-control" style={{width:50 + "%"}} value={value}  onChange={e => setValue(e.target.value)}/> 
                                                                        <span style={{padding:"2px"}}>até</span>
                                                                        <input type={inputType} className="form-control" style={{width:50 + "%"}} value={value2} onChange={e => setValue2(e.target.value)}/> 
                                                                    </div>
                                                                  : <div style={{display:"flex", padding:"7px", justifyContent:"center"}}>
                                                                        <div style={{width:50 + "%", display:"flex", justifyContent:"center"}}>
                                                                            <input type='radio' className="form-check-input" checked={value === 'true'} value={value}  onChange={e => setValue('true')}/>
                                                                            <span style={{marginLeft:"22px"}}>Sim</span>
                                                                        </div>
                                                                        <div style={{width:50 + "%", display:"flex", justifyContent:"center"}}>
                                                                            <input type='radio' className="form-check-input" checked={value === 'false'} value={value}  onChange={e => setValue('false')}/>
                                                                            <span style={{marginLeft:"22px"}}>Não</span>
                                                                        </div>    
                                                                    </div> 
                            }   
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={collect} 
                                                           value={collect} 
                                                           onClick={e => setCollect(!collect)} />
                                    <span ></span>
                                </label>
                                <span>Acumular pesquisas?</span>
                            </div>
                            <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
                                <span>E</span>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={operator} 
                                                           value={operator} 
                                                           onClick={e => setOperator(!operator)} />
                                    <span ></span>
                                </label>
                                <span>OU</span>
                            </div>
                        </div>
           
                            <div style={{marginTop:"5px", display:"flex", gap:"5px", flexDirection:"column",maxHeight:"250px", overflowY:"auto"}}>
                                { [...new Set(externalFilters !== undefined ? selectFilters.concat(externalFilters) : selectFilters)].map(_filter => {
                                    if (_filter.field !== '') {
                                        return (
                                            <div style={{padding:"5px", background:"#f8f8f9", display:"flex", borderRadius:"5px", border:"1px dashed #cacaca", justifyContent:"space-between"}}>
                                                <div style={{display:"flex", gap:"4px", flexWrap:"wrap"}}>
                                                    <span style={{background:"#fff", padding:"2px 4px", border:"5px", borderRadius:"5px"}}>{_filter.field === '' ? 'SEM REPETIDOS' : filters[_filter.field]?.text}</span> 
                                                    <span style={{background:"#fff", padding:"2px 4px", border:"5px", borderRadius:"5px"}}>{_arSearch.filter(_data => _data.value === _filter.key)[0].text}</span>
                                                    <span style={{background:"#fff", padding:"2px 4px", border:"5px", borderRadius:"5px", fontWeight:"bold"}}>{_filter.value === '' ? 'VAZIO' : _filter.value } {_filter.value2 ? ' e ' + _filter.value2 : ''}</span>
                                                </div>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <button className='btn btn-danger btn-sm' type="button" onClick={e => deleteFilter(_filter.field,_filter.value)}><i className='fa fa-xmark' style={{fontSize:"10px"}}/> </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
      
                        <div style={{display:"flex", marginTop:"3px"}}>
                            <button type="button" className="btn btn-success" style={{ width:"120px"}} onClick={e => {handleFilter()}}><em className="fa fa-search-plus"></em> Filtrar</button>
                            <button type="button" className="btn btn-warning" style={{marginLeft:"3px", width:"120px" }} onClick={e => {handleRemoveFilter()}}><em className="fa fa-search-minus"></em> Retirar Filtro</button>
                        </div>
                    </div>
                </CardBody>
           </Card>
        </Collapse>
    )
}