import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent} from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'
import { format   }        from 'date-fns'

String.prototype.replaceAll = function(str1, str2, ignore) 
{
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
} 

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCSATResult({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]                      = useState(false)
    const [tabActive, setTabActive]                  = useState(0)


    const [statusMessages, setStatusMessages]        = useState(-99) 
    const [comments, setComments]                    = useState('')

    const [dataMessages, setDataMessages]            = useState([])
    const [dataStatus, setDataStatus]                = useState([])

    const getCSat = async() => {
        await api.get('/getCSatStatus/0')
                 .then(response => {
                    setDataStatus(response.data.filter(_item => _item.idstatuscsat > 2 || _item.idstatuscsat === response.data[response.data.length - 1].idstatuscsat))
                    setStatusMessages(response.data[response.data.length - 1].idstatuscsat)
                 }).catch(e => {
                    console.log(e)
                 })  
    } 

    const setData = () => {
        setLoading(true)
        setStatusMessages(-99)
        if (data.messages !== undefined) {
            let _first = {
                create_at : data.data.create_at,
                csat_data : 0,
                idcompany : data.data.idcompany,
                idcsat    : data.data.idcsat, 
                iduser    : data.data.iduser,
                message   : data.data.messagesend,
                order     : 0,
                sender    : true
            }

            let _data = data.messages
            _data.unshift(_first)

            setDataMessages(_data || [])
            setTabActive(0)
            setComments(data.data.dealings || '')
        }
        setLoading(false)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-csat'),document.querySelector('#submit-csat').children[0].classList,document.querySelector('#submit-csat').lastChild.nodeValue)

        try {

            let _data = {statusMessages,
                         comments}

            await api.put('/csat-result/' + data.data.idcsat, _data)
                         .then(response => {
                            ShowMessages(response)
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-csat'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-csat'))
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-csat')) 
                            ShowMessages(response);
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-csat'))
            ShowMessages(response)
        }
    }

    
    useEffect(() => {
        setStatistic('modals\\modal-csat-result')
        getCSat()
    },[])

    useEffect(() => {
        setData()

    },[data,showModal])
   
    return (
      
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}>  
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-person-circle-question"></i> Avaliação CSAT
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth330px"/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    <span id="personal-data">Avaliação</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    <span id="documents">Fluxo de Respostas</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId={0}>
                                <div>
                                    <div style={{ display:"flex", flexDirection:"column"}}>
                                        <label>Status da Mensagem</label>
                                        <select className="form-select" value={statusMessages} onChange={e => setStatusMessages(parseInt(e.target.value))} disabled={data?.data?.idstatuscsat === 4}>
                                            <option value={-99} selected>Selecione</option>
                                                {dataStatus.map((item) => {
                                                    return(<option value={item.idstatuscsat}>{item.statuscsat}</option>)
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label>Tratativa</label>
                                    <textarea maxLength={2400} rows={10} className="form-control" name="comments" id="comments" value={comments} onChange={e => setComments(e.target.value)} disabled={data?.data?.idstatuscsat === 4}></textarea>
                                </div>          
                            </TabPane>
                            <TabPane tabId={1}>
                                <div style={{display:"flex", flexDirection:"column", backgroundColor:"#F0F2F5", height:"290px", overflowY:"auto"}}>
                                    {dataMessages.map(item => {
                                        return (
                                        <div style={{display:"flex", flexDirection:"column", backgroundColor:"#F0F2F5", margin:"7px"}}>
                                            {(item.sender) ?
                                                    <div style={{backgroundColor:"#FFFFFF",padding:"5px",borderRadius:"5px",maxWidth:"85%",width:"fit-content"}}>
                                                        <div dangerouslySetInnerHTML={{__html: item.message.replaceAll('\n','<br>')}}>
                                            
                                                        </div>
                                                        <small style={{fontSize:"9px"}}>{format(new Date(item.create_at),'dd/MM/yyyy HH:mm:ss')}</small>
                                                    </div>
                                            :
                                                <div style={{width:"100%",display:"flex", alignItems:"end", flexDirection:"column"}}>
                                                    <div style={{backgroundColor:"#D9FDD3", padding:"5px", maxWidth:"85%", borderRadius:"5px",marginTop:"5px", width:"fit-content", display:"flex", flexDirection:"column", textAlign:"right"}}>
                                                        <span>{item.message}</span>
                                                        <small style={{fontSize:"9px"}}>{item.response_at ? format(new Date(item.response_at),'dd/MM/yyyy HH:mm:ss') : ''}</small>
                                                    </div>
                                                    
                                                </div>
                                            }
                                        </div>)
                                    })}                         
                                </div>     
                            </TabPane>
                        </TabContent>     
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    {((data?.data !== undefined) && ([1,2,3].includes(data.data.idstatuscsat))) ? 
                        <Button type="submit" id="submit-csat" className={`btn-theme ${loading ? "hidden" : ""}`}>
                            <em className="fa fa-save"></em> Salvar
                        </Button>          
                    : ''}
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}