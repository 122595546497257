import React, { useCallback, useEffect, useState } from 'react';
import P from 'prop-types';

import Header from './Header'
import Sidebar from './Sidebar'
import KSContainer from './../../pages/layouts/Container'
import ChatUserList from './ChatUserList'
import ChatMessageContainer from './ChatMessageContainer'

import { NotificationContainer } from 'react-notifications'
import { socket } from './../../services/chatAPI'

import logo from './../../assets/logo-404.png'
import logoSGE from './../../assets/logoSGE.png'
import ModalAcceptedTerms from '../modals/modal-accepted-terms';
import { ACCEPT_TERMS, ReadDataStorage } from '../../services/auth';

const BasePage = props => {
    const [company, setCompany] = useState(-1)
    const [reset,setReset]      = useState(false)
    const [pages, setPages]     = useState(-1)

    const [reload, setReload] = useState(false);

    const handleReload = () => {
        setReload((state) => !state);
    };
    
    const hasTermsAccepted = useCallback(() => {
        return ReadDataStorage(ACCEPT_TERMS) === 'true';
    }, [reload]);

    useEffect(() => {
        setReset(true)
    },[company])

    useEffect(() => {
    
    },[pages])
    
    // if (!hasTermsAccepted()) {
    //     return (      <>
    //         <ModalAcceptedTerms showModal={true} handleReload={handleReload} />
    //         <NotificationContainer />
    //       </>
    //     );
    // }

    return (
        <>
            <div className="wrapper">
                <Header history={props.history} callbackCompany={setCompany}/>
                <Sidebar ActualCompany={company}/>
            
                <ChatUserList socket={socket}/>
            
                <section className="section-container">
                    <div id="content-tab" className={`${pages > 0 ? 'heigth100' : 'hidden'}`}>
                        <KSContainer reset={reset} callbackReset={setReset} callbackPages={setPages}/>
                    </div>  
                    <div className='box-base'>
                        {window.location.href.indexOf('sgecont') > 0 ? 
                            <img alt="" src={logoSGE} className="email-logo max-width-logo "/>
                        :
                            <img alt="" src={logo} className="email-logo"/>
                        }
                    </div> 
                    <ChatMessageContainer socket={socket}/>            
                </section>            
            </div>
            <NotificationContainer />
        </>
        )
}

export default BasePage;

BasePage.propTypes = {
  history: P.string,
};