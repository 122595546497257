import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from './../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from './../../services/apiStatistic'
import  loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalScheduling({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]       = useState(false)

    const [status,setStatus]          = useState('')
    const [hour, setHour]             = useState(null)

    const [dataStatus, setDataStatus] = useState([])

    const getData = async() => {
        await api.get('/scheduling-status/1')
                 .then(response => {
                    setDataStatus(response.data)
                 })
                 .catch(error => {
                    ShowMessages(error)
                 })
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        try {
            let _data = {status,
                         hour}

            await api.put('/scheduling/' + data.idscheduling, _data)
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-scheduling'))
                         } else
                             loadingButton(false,document.querySelector('#submit-scheduling'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-scheduling'))
                         ShowMessages(error) 
                     })
            
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-scheduling'))
            ShowMessages(response)
        }
    }
    
    const setData = (obj, clear) => {
        try {
            setStatus(clear ? 0 : obj.idschedulingstatus)
            setHour(clear ? 0 : obj.arrival_date === null ? '' : obj.arrival_date.substr(11,5))
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
        setStatistic('modals\\modal-scheduling')
    } ,[])

    useEffect(() => {
       if (editState)
           setData(data, false)
       else 
           setData({},true)
    } ,[editState, showModal])

    
    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-calendar-check"></i> Propriedades da Agenda
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Status</label>
                            <div>
                                <select className="form-select" required placeholder="Selecione" value={status} onChange={e => setStatus(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {dataStatus.map((data, key) => {
                                        return (<option key={key} value={data.value}>{data.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>  
                        <div>
                            <label>Horário</label>
                            <div className="input-group">
                                <TextInput  
                                    name="hours"
                                    id="hours"
                                    type="time"
                                    required
                                    errorMessage={{ required: "Por favor, informe um horário!" }}
                                    value={hour}
                                    className="form-control "
                                    onChange={e => setHour(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>  

                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-scheduling" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}