import React from 'react';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormRegister } from '../formregister-context';
import { MaskedInput } from 'rsuite';
import CurrencyInput from '../../../../components/moneyInput';

export default function FiscalDepartament() {
  const { fiscalDepartament, setFiscalDepartament } = useFormRegister();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const valueToUse =
      value === 'true' ? true : value === 'false' ? false : value;
    setFiscalDepartament((prev) => ({ ...prev, [name]: valueToUse }));
  };

  const handleChangeItem = (name, value) => {
    setFiscalDepartament((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeMaskedInput = (name, value) => {
    if (!value) {
      console.log('Event or event target is', value);
      return;
    }
    setFiscalDepartament((prev) => ({
      ...prev,
      branchcnpjs: {
        ...prev.branchcnpjs,
        [name]: value,
      },
    }));
  };

  return (
    <ValidationForm>
      <div
        style={{
          maxHeight: '330px',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '5px',
        }}
      >
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Empresa</Form.Label>
              <Form.Control
                type="text"
                name="fiscalcompanyname"
                value={fiscalDepartament.fiscalcompanyname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Pessoa de Contato</Form.Label>
              <Form.Control
                type="text"
                name="contactperson"
                value={fiscalDepartament.contactperson}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Telefone para Contato</Form.Label>
              <Form.Control
                type="text"
                name="contactphone"
                value={fiscalDepartament.contactphone}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={fiscalDepartament.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Município</Form.Label>
              <Form.Control
                type="text"
                name="municipality"
                value={fiscalDepartament.municipality}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Ramo de Atividade</Form.Label>
              <Form.Control
                type="text"
                name="businessactivity"
                value={fiscalDepartament.businessactivity}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Regime de Tributação</Form.Label>
              <Form.Control
                type="text"
                name="taxationregime"
                value={fiscalDepartament.taxationregime}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Regime de Apuração</Form.Label>
              <Form.Control
                type="text"
                name="assessmentregime"
                value={fiscalDepartament.assessmentregime}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui Filiais?</Form.Label>
              <div>
                <label className="mr-3">
                  <Form.Check
                    type="radio"
                    name="hasbranches"
                    label="Sim"
                    value={true}
                    checked={fiscalDepartament.hasbranches === true}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    name="hasbranches"
                    label="Não"
                    value={false}
                    checked={fiscalDepartament.hasbranches === false}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </Form.Group>
          </Col>

          {fiscalDepartament.hasbranches && (
            <>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Quantas Filiais?</Form.Label>
                  <Form.Control
                    type="number"
                    name="branchquantity"
                    min="0"
                    max="10"
                    value={fiscalDepartament.branchquantity}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>CNPJ das Filiais:</Form.Label>
                  <Row>
                    {Array.from({
                      length: fiscalDepartament.branchquantity,
                    }).map((_, index) => (
                      <Col md={4} key={index}>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                          ]}
                          keepCharPositions
                          guid
                          className="form-control mb-2"
                          name={`branchcnpjs[${index}]`}
                          placeholder={`CNPJ`}
                          value={
                            fiscalDepartament.branchcnpjs?.[
                              `branchcnpjs[${index}]`
                            ]
                          }
                          onChange={(value, event) => {
                            handleChangeMaskedInput(event.target?.name, value);
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui Certificado Digital?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="digitalcertificate"
                  value={true}
                  checked={fiscalDepartament.digitalcertificate === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="digitalcertificate"
                  value={false}
                  checked={fiscalDepartament.digitalcertificate === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Senha Prefeitura</Form.Label>
              <Form.Control
                type="password"
                name="municipalpassword"
                value={fiscalDepartament.municipalpassword}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Participa de Licitação?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="bidding"
                  value={true}
                  checked={fiscalDepartament.bidding === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="bidding"
                  value={false}
                  checked={fiscalDepartament.bidding === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Qual a frequência da CND?</Form.Label>
              <Form.Control
                type="text"
                name="cndfrequency"
                value={fiscalDepartament.cndfrequency}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Envio de Arquivos Físicos?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="physicalfiles"
                  value={true}
                  checked={fiscalDepartament.physicalfiles === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="physicalfiles"
                  value={false}
                  checked={fiscalDepartament.physicalfiles === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Qual Frequência?</Form.Label>
              <Form.Control
                type="text"
                name="physicalfilesfrequency"
                value={fiscalDepartament.physicalfilesfrequency}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Envio de Arquivos Digitais?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="digitalfiles"
                  value={true}
                  checked={fiscalDepartament.digitalfiles === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="digitalfiles"
                  value={false}
                  checked={fiscalDepartament.digitalfiles === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Qual Frequência?</Form.Label>
              <Form.Control
                type="text"
                name="digitalfilesfrequency"
                value={fiscalDepartament.digitalfilesfrequency}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui contratos de aluguel?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="rentalcontracts"
                  value={true}
                  checked={fiscalDepartament.rentalcontracts === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="rentalcontracts"
                  value={false}
                  checked={fiscalDepartament.rentalcontracts === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Existem particularidades ou benefícios que a empresa utiliza?
              </Form.Label>
              <Form.Control
                type="text"
                name="particularities"
                value={fiscalDepartament.particularities}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Possui débitos ou Parcelamento nas três esferas?
              </Form.Label>
              <Form.Control
                type="text"
                name="debtsorinstallments"
                value={fiscalDepartament.debtsorinstallments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui processos em aberto?</Form.Label>
              <Form.Control
                type="text"
                name="openprocesses"
                value={fiscalDepartament.openprocesses}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Média de Faturamento Mensal?</Form.Label>
              <CurrencyInput
                name="monthlyrevenue"
                id="monthlyrevenue"
                type="text"
                value={fiscalDepartament.monthlyrevenue}
                className="form-control text-right"
                onChange={(value) => {
                  handleChangeItem('monthlyrevenue', value);
                }}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Livros fiscais autenticados (Simples Nacional):
              </Form.Label>
              <Form.Control
                type="text"
                name="authenticatedfiscalbooks"
                value={fiscalDepartament.authenticatedfiscalbooks}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={8}>
            <Form.Group>
              <Form.Label>
                MUNICIPAL <br />
                Parcelamentos Ativos:
              </Form.Label>
  
              <Form.Control
                className="mb-3"
                type="text"
                name="municipalactiveinstallments"
                value={fiscalDepartament.municipalactiveinstallments}
               // onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Group>
              <Form.Label>
                ESTADUAL <br />
                Exige previsão de impostos?
              </Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="statetaxforecast"
                  value={true}
                  checked={fiscalDepartament.statetaxforecast === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="statetaxforecast"
                  value={false}
                  checked={fiscalDepartament.statetaxforecast === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
            {fiscalDepartament.statetaxforecast && (
              <Form.Group>
                <Form.Label>Quais?</Form.Label>
                <Form.Control
                  type="text"
                  name="statetaxdetails"
                  value={fiscalDepartament.statetaxdetails || ''}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui benefícios fiscais?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="fiscalbenefits"
                  value={true}
                  checked={fiscalDepartament.fiscalbenefits === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="fiscalbenefits"
                  value={false}
                  checked={fiscalDepartament.fiscalbenefits === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
            {fiscalDepartament.fiscalbenefits && (
              <Form.Group>
                <Form.Label>Quais benefícios?</Form.Label>
                <Form.Control
                  type="text"
                  name="fiscalbenefitsdetails"
                  value={fiscalDepartament.fiscalbenefitsdetails || ''}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Planejamento Tributário em andamento?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="taxplanning"
                  value={true}
                  checked={fiscalDepartament.taxplanning === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="taxPlanning"
                  value={false}
                  checked={fiscalDepartament.taxplanning === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
            {fiscalDepartament.taxplanning && (
              <Form.Group>
                <Form.Label>Data de Início:</Form.Label>
                <Form.Control
                  type="date"
                  name="taxplanningstartdate"
                  value={fiscalDepartament.taxplanningstartdate || ''}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Possui liminar de suspensão do PIS/COFINS na base do ICMS?
              </Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="piscofinssuspension"
                  value={true}
                  checked={fiscalDepartament.piscofinssuspension === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="piscofinssuspension"
                  value={false}
                  checked={fiscalDepartament.piscofinssuspension === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Possui liminar de DIFAL Simples Nacional?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Sim"
                  name="difalsimplenational"
                  value={true}
                  checked={fiscalDepartament.difalsimplenational === true}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Não"
                  name="difalsimplenational"
                  value={false}
                  checked={fiscalDepartament.difalsimplenational === false}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={8}>
            <Form.Group>
              <Form.Label>Parcelamentos Ativos:</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                name="activeinstallments"
                value={fiscalDepartament.activeinstallments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>OBSERVAÇÃO</Form.Label>
              <Form.Control
                as="textarea"
                name="observations"
                value={fiscalDepartament.observations}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </ValidationForm>
  );
}
