import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalEmployeer from '../modals/modal-employeer';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'
import setStatistic from './../../services/apiStatistic'
import {checkPermission} from './../../components/security'
import Swal from 'sweetalert2'

export default function Employee() {
    const [loading, setLoading]                            = useState(false)

    const getPeopleStatus = async() => {
     
    }

    useEffect(() => {
        getPeopleStatus()
        setStatistic('maintenance\\administrator')
    },[])
    

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <div>
        
            </div>                  
        </ContentWrapper>
    )
}