import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from './../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import CurrencyInput from './../../components/moneyInput';

import api from '../../services/api';
import setStatistic from './../../services/apiStatistic'
import  loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalDepartament({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                                  = useState(false)

    const [departament,setDepartamet]                            = useState('')
    const [idpeoplesupervision, setIdpeoplesupervision]          = useState(0)
    const [receivecomissionleaders, setReceivecomissionleaders]  = useState(false)
    const [percentual, setPercentual]                            = useState(0)

    const [employeer, setEmployeer]                              = useState([])

    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getemployee')
                     .then(response => {
                        if (response.ok) {
                            setEmployeer(response.data)
                        }
                     })
            
            setLoading(false)         
        } catch (response) {
            ShowMessages(response)    
        }
    }

    const setData = (obj,clear) => {
        setDepartamet(clear ? '' : obj.departament) 
        setIdpeoplesupervision(clear ? '' : obj.idpeoplesupervision) 
        setReceivecomissionleaders(clear ? '' : obj.receivecomissionleaders) 
        setPercentual(clear ? '' : obj.percentual)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-departament'),document.querySelector('#submit-departament').children[0].classList,document.querySelector('#submit-departament').lastChild.nodeValue)
        try {
            let _data = {departament,
                         idpeoplesupervision,
                         receivecomissionleaders,
                         percentual}

            if (editState)
                await api.put('/departament/' + data.iddepartament, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-departament'))
                         } else
                             loadingButton(false,document.querySelector('#submit-departament'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-departament'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/departament', _data)
                         .then(response => {
                         ShowMessages(response)
                         if (response.ok) {         
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-departament'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-departament'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-departament'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-departament'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-departament')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-people-group"></i> Propriedades de Departamento
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth250px"/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Departamento</label>
                            <div className="input-group">
                                <TextInput  
                                    name="departament"
                                    id="departament"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um departamento!" }}
                                    value={departament}
                                    className="form-control "
                                    onChange={e => setDepartamet(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>  
                        <div>
                            <label>Supervisor</label>
                            <div className="input-group">
                                <SelectGroup 
                                    name="idpeoplesupervision" 
                                    id="idpeoplesupervision" 
                                    placeholder="Selecione" 
                                    onChange={e => setIdpeoplesupervision(e.target.value)}
                                    value={idpeoplesupervision}
                                    className="form-select">
                                    <option value="">Selecione</option>
                                    {employeer.map((_item) => {
                                        return (<option value={_item.idpeople}>{_item.name}</option>)
                                    })}
                                </SelectGroup> 
                            </div>
                        </div>  
                        <div style={{ marginTop:"15px", borderRadius:"5px", border:"1px solid #cacaca"}}>
                            <div style={{marginLeft: "15px", display: "flex", marginTop:"-12px", background:"#fff", width:"fit-content"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={receivecomissionleaders} defaultChecked={receivecomissionleaders} value={receivecomissionleaders} onClick={e => setReceivecomissionleaders(!receivecomissionleaders)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{textAlign:"center", marginLeft:"5px"}}>Supervisor recebe comissão dos liderados?</span>
                            </div>
                            <div style={{ marginTop:"5px", padding:"10px 15px", width:"150px"}}>
                                <label>(%) Comissão</label>
                                <CurrencyInput  
                                    name="percentual"
                                    id="percentual"
                                    type="text"
                                    disabled={!receivecomissionleaders}
                                    errorMessage={{ required: "Por favor, informe uma comissão!" }}
                                    value={percentual}
                                    maskoptions={{ decimalLimit : '3'}}
                                    className="form-control text-right"
                                    onChange={setPercentual}
                                    autoComplete="off"/>
                            </div>

                        </div>                                    
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-departament" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}