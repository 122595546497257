import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMessagesWhats({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                     = useState(false)

    const [message,setMessage]                      = useState('')
    const [messageWhatsType, setMessageWhatsType]   = useState('')
    const [tipoAct, setTipoAct]                     = useState('')

    const [dataMessageType, setDataMessageType]     = useState([])
    const [dataStatusAct, setDataStatusAct]         = useState([])
    const [dataStatusActFull, setDataStatusActFull] = useState([])
 
    const getData = async() => {
        await api.get('/getmessages-whatsData')
                 .then(response => {
                    setDataMessageType(response.data.messagewhatstype)
                    setDataStatusAct(response.data.statusact)
                    setDataStatusActFull(response.data.statusact)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const setData = (obj,clear) => {
        setMessage(clear ? '' : obj.messagewhats) 
        setMessageWhatsType(clear ? '' : obj.idmessagewhatstype) 
        setTipoAct(clear ? '' : obj.idstatusact) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-message-whats'),document.querySelector('#submit-message-whats').children[0].classList,document.querySelector('#submit-message-whats').lastChild.nodeValue)
        try {
             let _data = {message,
                          messageWhatsType,
                          tipoAct}

            if (editState)
                await api.put('/messages-whats/' + data.idmessagewhats, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-message-whats')) 
                            } else 
                                loadingButton(false,document.querySelector('#submit-message-whats')) 
                        }).catch(response => {
                            loadingButton(false,document.querySelector('#submit-message-whats'))   
                            ShowMessages(response);
                         })
            else
                await api.post('/messages-whats', _data)
                         .then(response => {
                            ShowMessages(response) 
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-message-whats')) 
                             } else 
                                loadingButton(false,document.querySelector('#submit-message-whats')) 
                        }).catch(response => {
                            loadingButton(false,document.querySelector('#submit-message-whats'))   
                            ShowMessages(response);
                         })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-message-whats')) 
            ShowMessages(response)
        }
    }
    
    const handleMessageType = (value) => {
        setDataStatusAct(dataStatusActFull.filter((item) => {return (parseInt(value) === parseInt(item.idmessagewhatstype))}))
        setMessageWhatsType(value)
    }

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-messages-whats')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-whatsapp"></i> Propriedades de Mensagens do Whatsapp
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <div style={{display : "flex"}}>
                                <div style={{width:"100%"}}>
                                    <label>Procedimento</label>
                                    <select className="form-select" required placeholder="Selecione" value={messageWhatsType} onChange={e => handleMessageType(e.target.value)}>
                                        <option value="">Selecione</option>
                                        {dataMessageType.map((field, key) => {
                                            return (<option key={key} value={field.value}>{field.label}</option>)
                                        })}
                                    </select>
                                </div>
                                <div style={{marginLeft : "15px", width:"50%"}} className={`${[1,3].includes(parseInt(messageWhatsType)) ? '' : 'hidden'}`}>
                                    <label>Tipo Ato</label>
                                    <select className="form-select" placeholder="Selecione" value={tipoAct} onChange={e => setTipoAct(e.target.value)}>
                                        <option value="">Selecione</option>
                                        {dataStatusAct.map((field, key) => {
                                            return (<option key={key} value={field.value}>{field.label}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label>Mensagem</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="message"
                                        id="message"
                                        type="text"
                                        multiline
                                        rows={10}
                                        required
                                        maxLength={1999}
                                        errorMessage={{ required: "Por favor, informe uma mensagem!" }}
                                        value={message}
                                        className="form-control "
                                        onChange={e => setMessage(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                      
                        </div> 
                        {parseInt(messageWhatsType) === 0 ?
                            <div style={{marginTop: "15px", display: "flex", flexDirection: 'column', padding:"8px", backgroundColor:"#ddffdd", borderRadius:"5px", border:"1px dashed #cacaca"}}>
                                <label style={{fontWeight:"bold", fontSize:"16px"}}>Lista de Parâmetros</label>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[NOME]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o nome do destinatário da mensagem</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[DATA_ATUAL]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta a data atual do envio da mensagem</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[DATA_CASAMENTO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta a data do casamento</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[HORA_CASAMENTO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta a hora do casamento</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[NOME_NOIVO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o nome do noivo</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[NOME_NOIVA]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o nome da noiva</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[LOCAL_CASAMENTO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o local do casamento</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[REGIME_CASAMENTO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o regime do casamento</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"150px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[CELEBRANTE]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o nome do celebrante</span>
                                    </div>
                                </div>
                            </div>   
                        : ''}

                        {parseInt(messageWhatsType) === 1 ?
                            <div style={{marginTop: "15px", display: "flex", flexDirection: 'column', padding:"8px", backgroundColor:"#ddffdd", borderRadius:"5px", border:"1px dashed #cacaca"}}>
                                <label style={{fontWeight:"bold", fontSize:"16px"}}>Lista de Parâmetros</label>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[NOME]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta o nome do destinatário da mensagem</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[DATA_ATUAL]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Apresenta a data atual do envio da mensagem</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[NUM_PROTOCOLO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Número do protocolo do ATO</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[STATUS]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Último status do ato</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[STATUS_DESCRICAO]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Descrição do último status do ato</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[ESCREVENTE_RESPONSAVEL]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Nome do escrevente responsável pelo status do ATO</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[WHATS_ESCREVENTE_RESPONSAVEL]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Whatsapp do escrevente responsável pelo status do ATO</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[TELEFONE_ESCREVENTE_RESPONSAVEL]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Telefone do escrevente responsável pelo status do ATO</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                        <span >[DATA_RETIRADA]</span>
                                    </div>
                                    <div style={{paddingLeft:"10px"}}>
                                        <span>Data da retirada da escritura</span>
                                    </div>
                                </div>
                            </div>                                  
                        : ''}
                        {parseInt(messageWhatsType) === 3 ?
                            <>
                                <div style={{marginTop: "15px", display: "flex", flexDirection: 'column', padding:"8px", backgroundColor:"#ddffdd", borderRadius:"5px", border:"1px dashed #cacaca"}}>
                                    <label style={{fontWeight:"bold", fontSize:"16px"}}>Lista de Parâmetros</label>
                                    <div style={{display:"flex"}}>
                                        <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                            <span >[RECIBO]</span>
                                        </div>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span>Apresenta o número do recibo do registro </span>
                                        </div>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                            <span >[PROTOCOLO]</span>
                                        </div>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span>Apresenta o número do protocolo do registro </span>
                                        </div>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                            <span >[NOME]</span>
                                        </div>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span>Nome do usuário</span>
                                        </div>
                                    </div>                                
                                    <div style={{display:"flex"}}>
                                        <div style={{fontWeight:"bold", width:"275px", borderRight: "1px dashed #cacaca"}}>
                                            <span >[SALDO_CUSTAS]</span>
                                        </div>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span>Saldo das custas do registro</span>
                                        </div>
                                    </div>
                                </div>                         
                            </>
                        : ''}
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit"  id="submit-message-whats" className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}