import React from 'react';

import { isAuthenticated } from './services/auth';

import Employees from './pages/maintenance/employees';
import Company from './pages/maintenance/company';
import Profile from './pages/maintenance/profile';
import User from './pages/maintenance/users';
import UserGroup from './pages/maintenance/user-group';
import Occupations from './pages/maintenance/occupation';
import PermissionsGroup from './pages/maintenance/permissions-group';
import PermissionsUser from './pages/maintenance/permissions-user';
import ReportBill from './pages/reports/report-bill';
import Error404 from './pages/errors/404';
import Bill from './pages/maintenance/bill';
import BIReport from './pages/maintenance/bireport';
import LinksBI from './pages/maintenance/links-bi';
import Videos from './pages/maintenance/videos';
import Messages from './pages/maintenance/messages';
import MessagesWhats from './pages/maintenance/messages-whats';
import MyNumbers from './pages/maintenance/my-numbers';
import Alert from './pages/maintenance/alert';
import AlertAll from './pages/maintenance/alerts-all';
import NewsletterSystem from './pages/maintenance/newsletter-system';
import HistoryMessage from './pages/maintenance/history-messages';
import Leads from './pages/maintenance/leads';
import Campaign from './pages/maintenance/campaign';
import Scheduling from './pages/maintenance/scheduling';
import SettingsScheduling from './pages/maintenance/settings-scheduling';
import Holidays from './pages/maintenance/holidays';
import Commissions from './pages/maintenance/commissions';
import ReportCommissions from './pages/report-config/commissions';
import ReportInvoices from './pages/report-config/invoices';
import IntegrationEscriba from './pages/maintenance/integration-escriba';
import CSAT from './pages/maintenance/csat';
import CSATResult from './pages/maintenance/csat-result';
import Departament from './pages/maintenance/departament';
import Administrator from './pages/maintenance/administrator';
import NFsMissing from './pages/maintenance/nfs-missing';
import FormRegister from './pages/maintenance/form-register';
import CompanyDominio from './pages/maintenance/company-dominio';

const getObject = (uri, id, props, idmenu) => {
  switch (uri) {
    case '/employees':
      return <Employees />;
    case '/company':
      return <Company />;
    case '/profile':
      return <Profile />;
    case '/users':
      return <User />;
    case '/user-groups':
      return <UserGroup />;
    case '/bill':
      return <Bill />;
    case '/occupations':
      return <Occupations />;
    case '/link-bi':
      return <LinksBI />;
    case '/messages':
      return <Messages />;
    case '/whats-message':
      return <MessagesWhats />;
    case '/videos':
      return <Videos />;
    case '/qrcode':
      return <MyNumbers />;
    case '/alert-all':
      return <AlertAll />;
    case '/newsletter-system':
      return <NewsletterSystem />;
    case '/whats-history':
      return <HistoryMessage />;
    case '/leads':
      return <Leads />;
    case '/campaign':
      return <Campaign />;
    case '/scheduling':
      return <Scheduling />;
    case '/scheduling-settings':
      return <SettingsScheduling />;
    case '/nfs-missing':
      return <NFsMissing />;
    case '/companies-dominio':
      return <CompanyDominio/>;
    case '/holidays':
        return <Holidays />;
    case '/commission-table':
      return <Commissions />;
    case '/commissions':
      return <ReportCommissions />;
    case '/report-invoice':
      return <ReportInvoices />;
    case '/alert':
      return <Alert id={id} props={props} />;
    case '/bireport':
      return <BIReport id={id} props={props} idmenu={idmenu} />;
    case '/usergroup/permissions':
      return <PermissionsGroup id={id} props={props} />;
    case '/user/permissions':
      return <PermissionsUser id={id} props={props} />;
    case '/receipt':
      return <ReportBill id={id} props={props} />;
    case '/escriba':
      return <IntegrationEscriba />;
    case '/csat':
      return <CSAT />;
    case '/csat-result':
      return <CSATResult />;
    case '/departament':
      return <Departament />;
    case '/administrator':
      return <Administrator />;
    case '/form-register':
      return <FormRegister />;
    default:
      return <Error404 />;
  }
};

export default function getComponent(uri, id, props, idmenu) {
  return isAuthenticated ? getObject(uri, id, props, idmenu) : <Error404 />;
}
