import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import { differenceInMinutes, addMinutes, format, isValid   } from 'date-fns'

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalSettingsScheduling({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                             = useState(false)
    const [tabActive, setTabActive]                         = useState(0)

    const [dataSchedulingService, setDataSchedulingService] = useState([])

    const [total, setTotal]                                 = useState([])

    const [schedulingservice, setSchedulingService]         = useState('')
    const [startofservice, setStartofservice]               = useState('')
    const [endofservice, setEndofservice]                   = useState('')
    const [interval, setInterval]                           = useState('')
    const [active, setActive]                               = useState(false)
    const [available, setAvailable]                         = useState([])
    const [serviceSaturday, setServiceSaturday]             = useState(false)
    const [serviceSunday, setServiceSunday]                 = useState(false)
    const [serviceHoliday, setServiceHoliday]               = useState(false)

    const createGrid = (edit) => {
        if ((startofservice !== '') && (endofservice !== '') && (interval !== '')) {
            if ((isValid(new Date(2021,1,1,endofservice.substr(0,2),endofservice.substr(3,2)))) &&
                (isValid(new Date(2021,1,1,startofservice.substr(0,2),startofservice.substr(3,2))))) {

                let _total =  differenceInMinutes (new Date(2021,1,1,endofservice.substr(0,2),endofservice.substr(3,2)),
                                                   new Date(2021,1,1,startofservice.substr(0,2),startofservice.substr(3,2))) / interval
        
                let _start = new Date(2021,1,1,startofservice.substr(0,2),startofservice.substr(3,2))
                let _arr   = []
                let _data  = []

                for (let _i = 0; _i <= _total; _i++) {
                    _arr.push(format(_start,"HH:mm"))
                    _data.push({item  : 'input-' + format(_start,"HH:mm"),
                                hour  : format(_start,"HH:mm"),
                                type  : 'allDays',
                                value : 0},  
                               {item  : 'input-' + format(_start,"HH:mm"),
                                hour  : format(_start,"HH:mm"),
                                type  : 'Saturday',
                                value : 0}, 
                               {item  : 'input-' + format(_start,"HH:mm"),
                                hour  : format(_start,"HH:mm"),
                                type  : 'Sunday',
                                value : 0},
                               {item  : 'input-' + format(_start,"HH:mm"),
                                hour  : format(_start,"HH:mm"),
                                type  : 'Holiday',
                                value : 0}) 
                    _start = addMinutes(_start,interval)
                }
                setTotal(_arr)
                
                if (!edit) {
                    setAvailable(_data)
                }
            }
        } else {
            setTotal([])
            setAvailable([])
        }
    }

    const handleAvaliable = (value, name, hour,type) => {
        let _data = []
        let edit = false
        available.map((item) => {
            if ((item.item === name) && (item.type === type)) {
                _data.push({item : name, hour : hour, type : item.type, value : value === '' ? '' : parseInt(value)})
                edit = true
            } else
                _data.push(item)
        })

        if (!edit)
            _data.push({item : name, hour : hour, type : type, value : value === '' ? '' : parseInt(value)})
        setAvailable(_data)
        
    }

    const getData = async() => {
        await api.get('/getSchedulingServiceData')
                 .then(response => {
                     setDataSchedulingService(response.data)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-settings-scheduling'),document.querySelector('#submit-settings-scheduling').children[0].classList,document.querySelector('#submit-settings-scheduling').lastChild.nodeValue)
        try {
            let _data = {schedulingservice,
                         startofservice,
                         endofservice,
                         active,
                         serviceSaturday,
                         serviceSunday,
                         serviceHoliday,
                         interval,
                         available}

            if (editState)
                await api.put('/scheduling-settings/' + data.idschedulingsetting, _data)
                         .then(response => {
                             ShowMessages(response)
                             if (response.ok) {
                                 if (response.data.status === 200) {
                                     handleCloseModal()  
                                     handleReload(true)    
                                 } else
                                     loadingButton(false,document.querySelector('#submit-settings-scheduling'))
                            } else
                                loadingButton(false,document.querySelector('#submit-settings-scheduling'))
                        })
                         .catch( error => {
                             loadingButton(false,document.querySelector('#submit-settings-scheduling'))
                             ShowMessages(error) 
                       })
            else
                await api.post('/scheduling-settings', _data)
                         .then(response => {
                             ShowMessages(response)
                             if (response.ok) {         
                                 if (response.data.status === 200) {
                                     handleCloseModal()  
                                     handleReload(true)    
                                 } else 
                                     loadingButton(false,document.querySelector('#submit-settings-scheduling'))        
                            } else
                                 loadingButton(false,document.querySelector('#submit-settings-scheduling'))
                          })
                          .catch( error => {
                              loadingButton(false,document.querySelector('#submit-settings-scheduling'))
                              ShowMessages(error) 
                          })
             loadingButton(false,document.querySelector('#submit-settings-scheduling')) 
        } catch (response) {
            console.log(response)
            loadingButton(false,document.querySelector('#submit-settings-scheduling')) 
            ShowMessages(response)
        }
    }

    const setData = (obj,clear) => {
        setTabActive(0)
        setSchedulingService(clear ? '' : obj.idschedulingservice)
        setStartofservice(clear ? '' : obj.startofservice)
        setEndofservice(clear ? '' : obj.endofservice)
        setInterval(clear ? '' : obj.interval)
        setAvailable(clear ? '' : obj.availabletimes)
        setActive(clear ? false : obj.active)
        setServiceSaturday(clear ? false : obj.servicesaturday)
        setServiceSunday(clear ? false : obj.servicesunday)
        setServiceHoliday(clear ? false : obj.serviceholiday)
        createGrid(!clear)
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-settings-scheduling')
    } ,[])

    useEffect(() => {
        createGrid(editState)
    } ,[interval, startofservice, endofservice])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-calendar-check"></i> Configurações da Agenda
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <div style={{display : "flex"}}>
                                <div style={{width:"100%"}}>
                                    <label>Serviço</label>
                                    <select className="form-select" required placeholder="Selecione" value={schedulingservice} onChange={e => setSchedulingService(e.target.value)}>
                                        <option value="">Selecione</option>
                                        {dataSchedulingService.map((field, key) => {
                                            return (<option key={key} value={field.value}>{field.label}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div style={{display : "flex", marginTop:"5px"}}>
                                <div title="Horário que será atendido o primeiro cliente" style={{width:"25%"}}>
                                    <label>Hora Inicio</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="startofservice"
                                            id="startofservice"
                                            type="time"
                                            style={{padding: "0px 0.75rem"}}
                                            required
                                            errorMessage={{ required: "Por favor, informe a hora de inicio!" }}
                                            value={startofservice}
                                            className="form-control "
                                            onChange={e => setStartofservice(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div style={{marginLeft:"5px", width:"25%"}} title="Horário que será atendido o último cliente">
                                    <label>Hora Fim</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="endofservice"
                                            id="endofservice"
                                            type="time"
                                            style={{padding: "0px 0.75rem"}}
                                            required
                                            errorMessage={{ required: "Por favor, informe a hora do fim!" }}
                                            value={endofservice}
                                            className="form-control "
                                            onChange={e => setEndofservice(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div style={{marginLeft:"5px", width:"50%"}} title="Tempo gasto em cada atendimento">
                                    <label>Intervalo</label>
                                    <select className="form-select" required placeholder="Selecione" value={interval} onChange={e => setInterval(e.target.value)}>
                                        <option value="">Selecione</option>
                                        <option value="10">10 Minutos</option>
                                        <option value="20">20 Minutos</option>
                                        <option value="30">30 Minutos</option>
                                        <option value="40">40 Minutos</option>
                                        <option value="50">50 Minutos</option>
                                        <option value="60">1 Hora</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{display : "flex", marginTop:"5px"}}>
                                <div style={{marginLeft: "15px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={active} defaultChecked={active} value={active} onClick={e => setActive(!active)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{textAlign:"center"}}>Ativar Serviço?</span>
                                </div>
                                <div style={{marginLeft: "15px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={serviceSaturday} defaultChecked={serviceSaturday} value={serviceSaturday} onClick={e => setServiceSaturday(!serviceSaturday)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{textAlign:"center"}}>Atendimento aos Sábados?</span>
                                </div>
                                <div style={{marginLeft: "15px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={serviceSunday} defaultChecked={serviceSunday} value={serviceSunday} onClick={e => setServiceSunday(!serviceSunday)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{textAlign:"center"}}>Atendimento aos Domingos?</span>
                                </div>
                                <div style={{marginLeft: "15px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={serviceHoliday} defaultChecked={serviceHoliday} value={serviceHoliday} onClick={e => setServiceHoliday(!serviceHoliday)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{textAlign:"center"}}>Atendimento aos Feriados?</span>
                                </div>
                            </div>                        
                        </div>     
                                           
                        <div style={{marginTop:"10px"}} className={`${total.length > 0 ? '' : 'hidden'}`}>
                            <span>Disponibilidade por horário</span>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                        <span>Dias de Semana</span>
                                    </NavLink>
                                </NavItem>
                                {serviceSaturday ? 
                                    <NavItem>
                                        <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                            <span>Sábado</span>
                                        </NavLink>
                                    </NavItem>
                                : ''}
                                {serviceSunday ? 
                                    <NavItem>
                                        <NavLink className={tabActive === 2 ? "active" : ""} onClick={() => { setTabActive(2)}}>
                                            <span>Domingo</span>
                                        </NavLink>
                                    </NavItem>
                                : '' }
                                {serviceHoliday ? 
                                    <NavItem>
                                        <NavLink className={tabActive === 3 ? "active" : ""} onClick={() => { setTabActive(3)}}>
                                            <span>Feriados</span>
                                        </NavLink>
                                    </NavItem>
                                : '' }
                            </Nav>
                                <TabContent activeTab={tabActive}>
                                    <TabPane tabId={0}>
                                        <div  className="box-scheduling">
                                            {total.map((item, key) => {
                                                if (available !== undefined) {
                                                    let _value = available.filter((data) => {return (data.item === 'input-' + item && data.type === 'allDays')})//[0].value
                                                    if (_value.length <= 0) 
                                                        _value = 0
                                                    else
                                                        _value = _value[0].value
                                                    
                                                    return(<div className="item-scheduling" key={key}>
                                                               <span style={{marginRight:"5px"}}>{item}</span>
                                                               <input type="integer" className="form-control" value={_value} onChange={e => handleAvaliable(e.target.value,'input-' + item, item,'allDays')}/>
                                                            </div>)
                                                }
                                            })}
                                        </div>
                                    </TabPane>
                                    
                                    <TabPane tabId={1}>
                                        <div  className="box-scheduling">
                                            {total.map((item, key) => {
                                                if (available !== undefined) {
                                                    let _value = available.filter((data) => {return (data.item === 'input-' + item  && data.type === 'Saturday')})//[0].value
                                                    if (_value.length <= 0) 
                                                        _value = 0
                                                    else
                                                        _value = _value[0].value
                                                    
                                                    return(<div className="item-scheduling" key={key}>
                                                               <span style={{marginRight:"5px"}}>{item}</span>
                                                               <input type="integer" className="form-control" value={_value} onChange={e => handleAvaliable(e.target.value,'input-' + item, item,'Saturday')}/>
                                                            </div>)
                                                }
                                            })}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <div  className="box-scheduling">
                                            {total.map((item, key) => {
                                                if (available !== undefined) {
                                                    let _value = available.filter((data) => {return (data.item === 'input-' + item && data.type === 'Sunday')})//[0].value
                                                    if (_value.length <= 0) 
                                                        _value = 0
                                                    else
                                                        _value = _value[0].value
                                                    
                                                    return(<div className="item-scheduling" key={key}>
                                                               <span style={{marginRight:"5px"}}>{item}</span>
                                                               <input type="integer" className="form-control" value={_value} onChange={e => handleAvaliable(e.target.value,'input-' + item, item,'Sunday')}/>
                                                            </div>)
                                                }
                                            })}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <div  className="box-scheduling">
                                            {total.map((item, key) => {
                                                if (available !== undefined) {
                                                    let _value = available.filter((data) => {return (data.item === 'input-' + item && data.type === 'Holiday')})//[0].value
                                                    if (_value.length <= 0) 
                                                        _value = 0
                                                    else
                                                        _value = _value[0].value
                                                    
                                                    return(<div className="item-scheduling" key={key}>
                                                               <span style={{marginRight:"5px"}}>{item}</span>
                                                               <input type="integer" className="form-control" value={_value} onChange={e => handleAvaliable(e.target.value,'input-' + item, item,'Holiday')}/>
                                                            </div>)
                                                }
                                            })}
                                        </div>
                                    </TabPane>
                                </TabContent>
                             </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit"  id="submit-settings-scheduling" className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}