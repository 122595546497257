import { Form, Row, Col } from 'react-bootstrap';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import MaskWithValidation from '../../../../components/maskInput';
import CEP from 'cep-promise';
import { useFormRegister } from '../formregister-context';
import { MaskedInput } from 'rsuite';

export default function CompanyData() {
  const { companyData, setCompanyData } = useFormRegister();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeItem = (name, value) => {
    setCompanyData((prev) => ({ ...prev, [name]: value }));
  };

  const getAddress = (value) => {
    let _cep = value.replace(/\D/g, '');
    if (_cep.length === 8) {
      CEP(_cep).then((data) => {
        console.log(data);
        handleChangeItem('address', data.street);
        handleChangeItem('neighborhood', data.neighborhood);
        handleChangeItem('uf', data.state);
        handleChangeItem('city', data.city);
      });
    } else {
      handleChangeItem('address', '');
      handleChangeItem('neighborhood', '');
      handleChangeItem('city', '');
      handleChangeItem('uf', '');
    }
  };

  return (
    <ValidationForm>
      <div
        style={{
          maxHeight: '330px',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '5px',
        }}
      >
        <Row className="mb-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Grupo / Empresa</Form.Label>
              <Form.Control
                type="text"
                maxLength={100}
                autoComplete="off"
                name="name"
                value={companyData.name}
                autoFocus={true}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="companyname"
                autoComplete="off"
                maxLength={100}
                value={companyData.companyname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label>Data da Integração</Form.Label>
              <Form.Control
                type="date"
                name="integrationdate"
                value={companyData.integrationdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Data de Entrada</Form.Label>
              <Form.Control
                type="date"
                name="entrydate"
                value={companyData.entrydate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Proprietário</Form.Label>
              <Form.Control
                type="text"
                name="owner"
                value={companyData.owner}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col
            md={3}
            className="d-flex align-items-center text-nowrap"
            style={{ marginLeft: '-5px' }}
          >
            <Form.Label className="mr-2">Tipo Empresa:</Form.Label>
            <Form.Check
              style={{ marginLeft: '15px', cursor: 'pointer' }}
              type="switch"
              id="pessoa-fisica-switch"
              name="kindofperson"
              label="Pessoa Física"
              value="pessoa-fisica"
              checked={companyData.kindofperson === 0}
              onChange={() => handleChangeItem('kindofperson', 0)}
            />
          </Col>
          <Col md={3} className="d-flex align-items-center">
            <Form.Check
              style={{ marginLeft: '35px', cursor: 'pointer' }}
              type="switch"
              id="pessoa-juridica-switch"
              name="kindofperson"
              label="Pessoa Jurídica"
              value="pessoa-juridica"
              checked={companyData.kindofperson === 1}
              onChange={() => handleChangeItem('kindofperson', 1)}
            />
          </Col>
          {companyData.kindofperson === 1 && (
            <Col md={6}>
              <Form.Group>
                <Form.Label>CNPJ</Form.Label>
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  keepCharPositions
                  guide
                  className="form-control"
                  name="cnpj"
                  value={companyData.cnpj}
                  onChange={(value) => {
                    handleChangeItem('cnpj', value);
                  }}
                />
              </Form.Group>
            </Col>
          )}
          {companyData.kindofperson === 0 && (
            <Col md={6}>
              <Form.Group>
                <Form.Label>CPF</Form.Label>
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  className="form-control"
                  name="ownerCpf"
                  value={companyData.cpf}
                  onChange={(value) => {
                    handleChangeItem('cpf', value);
                  }}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label>I.E.</Form.Label>
              <Form.Control
                type="text"
                name="ie"
                value={companyData.ie}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>I.M.</Form.Label>
              <Form.Control
                type="text"
                name="im"
                value={companyData.im}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '17%' }}>
            <label>CEP</label>
            <div className="input-group">
              <MaskWithValidation
                name="cep"
                id="cep"
                type="text"
                errorMessage={{
                  required: 'Por favor, informe um CEP!',
                }}
                className="form-control"
                value={companyData.cep}
                onChange={(e) => {
                  getAddress(e.target.value);
                  handleChange(e);
                }}
                autoComplete="off"
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '.',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  '-',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
              />
            </div>
          </div>

          <div style={{ marginLeft: '15px', width: '64%' }}>
            <label>Endereço</label>
            <div className="input-group">
              <TextInput
                name="address"
                id="address"
                type="text"
                disabled
                maxLength={100}
                value={companyData.address}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>

          <div style={{ marginLeft: '15px', width: '19%' }}>
            <label>Número</label>
            <div className="input-group">
              <TextInput
                name="number"
                id="number"
                type="text"
                maxLength={15}
                value={companyData.number}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '29%' }}>
            <label>Complemento</label>
            <div className="input-group">
              <TextInput
                name="complement"
                id="complement"
                type="text"
                value={companyData.complement}
                maxLength={100}
                style={{ height: '2.1875rem' }}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>

          <div style={{ width: '29%', marginLeft: '15px' }}>
            <label>Bairro</label>
            <div className="input-group">
              <TextInput
                name="neighborhood"
                id="neighborhood"
                type="text"
                disabled
                maxLength={100}
                value={companyData.neighborhood}
                style={{ height: '2.1875rem' }}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>

          <div style={{ width: '29%', marginLeft: '15px' }}>
            <label>Cidade</label>
            <div className="input-group">
              <TextInput
                name="city"
                id="city"
                type="text"
                disabled
                maxLength={100}
                value={companyData.city}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="mb-3" style={{ width: '13%', marginLeft: '15px' }}>
            <label>UF</label>
            <div className="input-group">
              <TextInput
                name="uf"
                id="uf"
                type="text"
                disabled
                maxLength={2}
                value={companyData.uf}
                className="form-control"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela integração contábil?</Form.Label>
              <Form.Control
                type="text"
                name="accountingintegrationresponsible"
                value={companyData.accountingintegrationresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela supervisão contábil?</Form.Label>
              <Form.Control
                type="text"
                name="accountingsupervisionresponsible"
                value={companyData.accountingsupervisionresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela integração pessoal?</Form.Label>
              <Form.Control
                type="text"
                name="personalintegrationresponsible"
                value={companyData.personalintegrationresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela supervisão pessoal?</Form.Label>
              <Form.Control
                type="text"
                name="personalsupervisionresponsible"
                value={companyData.personalsupervisionresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela integração fiscal?</Form.Label>
              <Form.Control
                type="text"
                name="fiscalintegrationresponsible"
                value={companyData.fiscalintegrationresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Responsável pela supervisão fiscal?</Form.Label>
              <Form.Control
                type="text"
                name="fiscalsupervisionresponsible"
                value={companyData.fiscalsupervisionresponsible}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label>Data de constituição da empresa:</Form.Label>
              <Form.Control
                type="date"
                name="companyestablishmentdate"
                value={companyData.companyestablishmentdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Início das atividades na SGECONT:</Form.Label>
              <Form.Control
                type="date"
                name="activitystartdate"
                value={companyData.activitystartdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mt-2">
            <Form.Group>
              <Form.Label className="mb-2">
                Qual a tributação da Empresa? (Últimos 5 anos)
              </Form.Label>
              <Form.Control
                type="text"
                name="companytaxation"
                value={companyData.companytaxation}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group>
              <Form.Label>Observações</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={2400}
                className="form-control"
                name="comments"
                value={companyData.comments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </ValidationForm>
  );
}
